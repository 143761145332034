const st = {};
const ht = {};
const lt = {};
const it = {};
const pt = {};

const getGenderText = (geschlecht, hideprivatmarker) => {
  var genderText = '';
  geschlecht = parseInt(geschlecht);
  hideprivatmarker = parseInt(hideprivatmarker);
  if (geschlecht===4 && !hideprivatmarker) {
    genderText = ' (' + st['bwh.room.titel.private'] + ')';
  }
  else if (geschlecht===2) {
    genderText = ' (' + st['bwh.room.titel.mixed'] + ')';
  }
  else if (geschlecht===0) {
    genderText = ' (' + st['bwh.room.titel.female'] + ')';
  }
  else if (geschlecht===1) {
    genderText = ' (' + st['bwh.room.titel.male'] + ')';
  }
  return genderText;
}
  
const splitText = (s) => {
  var titel = '';
  var text = '';
  var h1 = s.indexOf('h1. ');
  if (0 <= h1) {
    var nl = s.indexOf("\n", h1);
    if (0 <= nl) {
      titel = s.substr(h1+4, nl-h1-4);
      text = s.substr(nl+1);
    }
    else {
      titel = s.substr(h1+4);
    }
  }
  else {
    text = s;
  }
  
  return {titel, text};
};

const replaceRateText = (t, moment, dateFormat) => {
  if (!t) {
    return '';
  }
  for(var i in st) {
    if (i.substr(0,9)==='bwh.rate.') {
      t = t.replace(new RegExp(i, 'g'), st[i]);
    }
  }
  t = t.replace(/%datelong:([0-9]{8})%/g, (m, d) => { return moment(d).format(dateFormat)} )
  return t;
}


  
export { st, ht, pt, lt, it, getGenderText, splitText, replaceRateText };