import React from 'react';
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';

class BwhMap extends React.Component {
  render () {
    const {konfig} = this.props;

    const mapStyles = {
      width: '100%',
      height: '100%',
    };

    return (
      <Map
          google={this.props.google}
          zoom={17}
          style={mapStyles}
          initialCenter={{ lat: konfig.latitude, lng: konfig.longitude}}
      > 
        <Marker position={{ lat: konfig.latitude, lng: konfig.longitude}} title={konfig.hostelname} />   
      </Map>
    );
  }
}

export default GoogleApiWrapper(
  (props) => ({
    apiKey: props.konfig.mapapikey,
    language: 'en',
  }
))(BwhMap)