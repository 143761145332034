import React from 'react';
import { textileConvert, getTitelFromText, getTextFromText } from './common';
import BwhMap from './BwhMap'

export class ConfirmInfo extends React.Component {

  render (){
    const { appState, teststyle } = this.props;
    const { konfig } = appState;
    
    var confirminfotexte = [];  
    var ct = appState.confirminfotext;
    if (ct) {
      for (var i=0; i<ct.length; i++) {
        var text = getTextFromText(ct[i]);
        confirminfotexte.push({
          titel: getTitelFromText(ct[i]),
          text: textileConvert(text),
        });
      }
    }
    
    if (appState.status !== 'change' && appState.status !== 'gebucht') {
      return null;
    }
    
    return (    
      <React.Fragment>
        <div id="confirminfo_0_wrap" className="filterbereich-wrap clearfix" style={teststyle}>
          <div id="hostelMapContact_wrap" className="filterbereich clearfix">
            <div id="map_contact_wrap">
              <BwhMap konfig={konfig} />
            </div>
          {konfig.hostelmap &&
            <div id="hostelmapimg" className="clearfix formularansicht">
              <img src={konfig.hostelmap} style={{width:"80%"}} alt="Map" />
            </div>
          }
          </div>
  {confirminfotexte.map((el, k) => (
          <div className="filterbereich clearfix" key={k}>
            <div className="filterbereich-titel">
              <p className="filterbereich-titeltext" dangerouslySetInnerHTML={{__html: el.titel}} />
            </div>
            <div className="clearfix bereichinhalt-wrap">
              <div className="clearfix bereichinhalt">
                <p className="bereichinhalt-text" dangerouslySetInnerHTML={{__html: el.text}} />
              </div>
            </div>
          </div>
  ))}
        </div>
        </React.Fragment>
    )
  }
}
export default ConfirmInfo;
