import React from 'react';
import { textileConvert } from './common';
import { pt } from './texte'

export class Confirm extends React.Component {
  render (){
    const { appState, checkConfirm, dispCheckInfo } = this.props;
    
    var gelesenhacken = parseInt(appState.konfig.gelesenhacken);
    var checkboxes = [];
    for (var k in pt.hostel_zahlunggelesen) {
      if (gelesenhacken < 1) break;
      gelesenhacken--;
      checkboxes.push(pt.hostel_zahlunggelesen[k])
    }
    if(!checkboxes.length) {
      return null;
    }

    return (
        <div className="clearfix formularansicht">
  {checkboxes.map((el, k) => (
          <div id="zahlungsdatenFormularGelesenHacken0" className="formularfeld clearfix"  key={k}>
            <div className="formularfeld-feld formularfeld-checkbox clearfix">
              <input id="zahlungsdatenFormularConfirm0" type="checkbox" name={"confirm"+k} checked={appState['confirm'+k]} onChange={checkConfirm(k)} />
            </div>
            <div id="zahlungsdatenFormularBestaetigungTextWrapper0" title="">
              <div id="zahlungsdatenFormularBestaetigungText0" className="zahlungsdaten_formular_bestaetigung_text" dangerouslySetInnerHTML={{__html: textileConvert(el)}}></div>
    {pt.hostel_zahlunggeleseninfo && pt.hostel_zahlunggeleseninfo[k+1] &&
              <i id="zahlungsdatenFormularGelesenHackenIcon0" className="flaticon-arrows-1 linkicon" onClick={dispCheckInfo(k+1)}></i>
    }
            </div>
          </div>
  ))}
        </div>
    );
  }
}
export default Confirm;

