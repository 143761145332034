import React from 'react';
import { pt } from './texte'
import Leistung from './Leistung';

export class Leistungen extends React.Component {

  render (){
    const {appState, ...other} = this.props;

    var leistungen = [];
    var leistObj = {};
    var children = {}
    const services = appState.selBooking.angebot?appState.selBooking.angebot.list[0].services:[];
    for (var i=0;i<services.length;i++) {
      var z = services[i];
      z.anzahl = appState.zusatzAnzahl[z.zkat]?appState.zusatzAnzahl[z.zkat]:0;
      leistungen.push(z);
      leistObj[z.zkat] = z;
      if (z.anz_mod_list && z.anz_mod_list.ChildService) {
        z.anz_mod_list.ChildService.map(sid=>{children[sid]=z.zkat}) 
      }
    }
    
    if (leistungen && leistungen.length) {
      const leistList = [];
      for (i=0; i<leistungen.length; i++) {
        const zusatz = leistungen[i];
        zusatz.def_type = parseInt(zusatz.def_type);
        zusatz.online_type = parseInt(zusatz.online_type);
        zusatz.notonbwh = parseInt(zusatz.notonbwh);
        zusatz.zkat = parseInt(zusatz.zkat);
        if (!children[zusatz.zkat]) {
          if (zusatz.notonbwh !== 1 && (pt.zusatz_bezeichnung[zusatz.zkat]!=='NotActive')) {
            leistList.push(<Leistung data={zusatz} leistObj={leistObj} key={i} {...other}/>);
          }
        }
      }
      return leistList;
    }
    else {
      return null;
    }
  }
}
export default Leistungen;
