import React, {Component} from 'react';
import { st, pt } from './texte'
import { calcPreisJetzt } from './common.js'
import Confirm from './Confirm'
import {CardNumberElement, CardExpiryElement, CardCVCElement, injectStripe} from 'react-stripe-elements';
import { ReactComponent as PfeilRechtsFettIcon } from './img/pfeil_rechts_fett.svg'

class StripeForm extends Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
  }

  async submit(ev) {
    this.props.setAppState({error: ''});
    this.props.stripe
      .createPaymentMethod('card', {})
      .then( async ({error, paymentMethod}) => {
        if (error) {
          this.props.logtext('stripe: client error: ' + JSON.stringify(error));
        }
        if (!paymentMethod || !paymentMethod.id) {
          if (error) {
            var fehlertext = st['bwh.errormessage.booking.10'];
            this.props.setAppState({error: fehlertext.replace('{FEHLER}', error.message)});
          }
          else {
            this.props.setAppState({error: st['bwh.errormessage.booking.6']});
          }
          return;
        }
        const intent = await this.props.setBooking(paymentMethod?paymentMethod.id:'');

        intent.action_intent && this.props.stripe.handleCardAction(
          intent.action_intent
        ).then(async (result) => {
          if (result.error) {
            var fehlertext = st['bwh.errormessage.booking.10'];
            this.props.setAppState({error: fehlertext.replace('{FEHLER}', result.error.message)});
          } else {
            await this.props.setBooking(result.paymentIntent.id);
          }
        });
      });    
  }

  render() {
    const { preis, appState, ...others} = this.props;
    
    const PreisJetzt = calcPreisJetzt(appState);
    return (
    <div>
      <div id="zahlungsdatenFormular_wrap">
        <div className="clearfix viewheader">
          <p id="zahlungsdatenFormular_headertext" className="viewheadertext">{st['bwh.zahlungsdaten.formular.headertext']}</p>
        </div>
        <div id="zahlungsdatenFormular_formular" className="clearfix formularansicht">
          <div className="formularlegende clearfix">
            <p id="zahlungsdatenFormularLegende" className="formularlegende-text">{st['bwh.zahlungsdaten.legende']}</p>
          </div>
          <div id="zahlungsdatenFormular_formular_mitstripe" className="clearfix formularansicht">
            <div id="zahlungsdatenFormularStripeNummerFeld" className="formularfeld clearfix">
              <p id="zahlungsdatenFormularStripeNummerLabel" className="formularfeld-label">{st['bwh.zahlungsdaten.formular.stripenummer']}</p>
              <CardNumberElement className="stripe-feld" style={{base: {fontSize: '18px'}} } />
            </div>
            <div id="zahlungsdatenFormularStripeAblaufFeld" className="formularfeld clearfix">
              <p id="zahlungsdatenFormularStripeAblaufLabel" className="formularfeld-label">{st['bwh.zahlungsdaten.formular.stripeablauf']}</p>
              <CardExpiryElement className="stripe-feld" style={{base: {fontSize: '18px'}}} />
            </div>
            <div id="zahlungsdatenFormularStripeCVCFeld" className="formularfeld clearfix">
              <p id="zahlungsdatenFormularStripeCVCLabel" className="formularfeld-label">{st['bwh.zahlungsdaten.formular.stripecvc']}</p>
              <CardCVCElement className="stripe-feld" style={{base: {fontSize: '18px'}}} />
            </div>
            <div id="formularfeld clearfix" className="formularfeld clearfix">
            {PreisJetzt > 0?
              <h3>{st['bwh.zahlungpreis'].replace('%{preis}', PreisJetzt.toFixed(appState.paymentinfo.decimal) + ' ' + appState.paymentinfo.waehrung)}</h3>
              :
              <h3>{st['bwh.zahlung0']}</h3>
            }
            </div>
          </div>
        </div>        
      </div>        
      <Confirm appState={appState} {...others}/>
      <div id="boo_now_leiste_unten" className="book-now-leiste-wrap clearfix">
        <div className="book-now-leiste clearfix handcursor" onClick={this.submit}>
          <div className="book-now-wrap clearfix book-now-wrap-hover">
            <p id="book_now_preis">{st['bwh.preis.total']} {preis}</p>
            <div className="book-now clearfix">
              <p id="book_now_text_unten" className="book-now-text">{pt.hostel_booknowfinish}</p>
              <PfeilRechtsFettIcon />
            </div>
          </div>
        </div>
      </div>
    </div>        
    );
  }
}

export default injectStripe(StripeForm);