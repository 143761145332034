import React from 'react';
import { findRate } from './common';
import Angebot from './Angebot';
import { st } from './texte'
import LoadingIcon from './img/loading.svg';
import CalendarBild from './img/calendar_31.png'
import RoomBild from './img/other-room.jpg'

export class Angebote extends React.Component {

  intersect = (a, b) => {
    var t;
    if (b.length > a.length) {  // indexOf to loop over shorter
      t = b;
      b = a;
      a = t;
    } 
    return a
      .filter(function (e) {
        return b.indexOf(e) > -1;
      })
      .filter(function (e, i, c) { // extra step to remove duplicates
        return c.indexOf(e) === i;
    });
  }
  
  render (){
    const {res, selectedAngebotId, angebotDifferent, konfig, selectedRate, ...other} = this.props;

    if (!res) {
      return ( 
        <div className="loading">
          <img src={LoadingIcon} style={{display:"block", margin:'auto'}} alt={"Loading"}  />
        </div>    
      )
    }
    
    if (res.length) {
      var dorms = res;
      var i;
      var j;
      var k;
      var id;
      var sortdorms = [];
      var set = false;
      var gemeinsameIcons = [];
      for (i=0; i<dorms.length; i++) {
        id = dorms[i].angId;
        var ag = dorms[i].list[0];
        if (id === selectedAngebotId) {
          sortdorms[i] = '0-0';
        }
        else {
          sortdorms[i] = findRate(ag.rates, selectedRate).preis.total + '-' + ag.rooms[0].geschlecht;
        }
        for (k=0; k<ag.rooms.length; k++) {
          var icons = konfig.icons[ag.rooms[k].pkat];
          if (!set) {
            gemeinsameIcons = icons;
            set = true;
          }
          else {
            gemeinsameIcons = this.intersect(gemeinsameIcons, icons);
          }
        }
      }
      sortdorms.sort( function(a, b) {
        var a_n = a.indexOf("-");
        var a_pkat = a.substr(0, a_n);
        var a_g = a.substr(a_n+1);
        var b_n = b.indexOf("-");
        var b_pkat = b.substr(0, b_n);
        var b_g = b.substr(b_n+1);
        var ret = 0;
        if (a_pkat === b_pkat) {
          ret = b_g - a_g;
          if ((0 === a_g && 1 === b_g) || (1 === a_g && 0 === b_g)) {
            ret = a_g - b_g;
          }
        }
        else {
          ret = a_pkat - b_pkat;
        }
        return ret;
      });

      var sortdorms2 = [];
      var pr = '';
      for (i=0; i<dorms.length; i++) {
        id = dorms[i].angId;
        if (id === selectedAngebotId) {
          pr = '0-0';
        }
        else {
          pr = dorms[i].list[0].rates[0].preis.total + '-' + dorms[i].list[0].rooms[0].geschlecht;
        }
        var a = sortdorms.indexOf(pr);
        while(0 <= sortdorms2.indexOf(a)) {
          a = sortdorms.indexOf(pr, a+1);
        }
        sortdorms2[i] = a;
      }
    
      const angebotlist = [];
      for (i=0; i<dorms.length; i++) {
        j = sortdorms2.indexOf(i);
        id = dorms[j].angId;
        var ratePreis = dorms[j].list[0].rates[0].preis;
        var p = ratePreis.total;
        if (konfig.pricenocomm) {
          p = ratePreis.room + ratePreis.rabatt;
          if (konfig.pricenocomm === 2) { // NetPrice+Fee
            p += ratePreis.commission;
          }
          if (konfig.pricenocomm === 3) { // NetPrice+Service
            p = ratePreis.total - ratePreis.commission;
          }
        }
        var data = dorms[j].list[0];
        data.angId = dorms[j].angId;
        data.nights = dorms[j].nights;
        data.persons = dorms[j].persons;
        data.aklist = dorms[j].aklist;
        data.preis = p;
        data.preisAk = ratePreis.roomAk;
        angebotlist.push(<Angebot data={data} hasRates={dorms[j].list[0].hasRates} 
          konfig={konfig} key={i} selectedAngebotId={selectedAngebotId} gemeinsameIcons={gemeinsameIcons} {...other}/>);
      }
      return angebotlist;
    }
    else {
      return (
        <div id="suchresultate" className="clearfix datenansicht">
          <div className="clearfix angebot_wrap">
            <div className="clearfix angebot">
              <div className="room vis differentDate" onClick={angebotDifferent(0)} style={{backgroundImage:'url('+CalendarBild+')'}}>
                <div className="clearfix choice choice_selected">
                  <p className="alternativ_angebot_text">{st['bwh.angebote.different.date']}</p>
                </div>
                <div className="clearfix roomverlauf"></div>
              </div>
            </div>
          </div>
          <div className="clearfix angebot_wrap"><div className="clearfix angebot">
            <div className="room vis differentRoom" onClick={angebotDifferent(1)} style={{backgroundImage:'url('+RoomBild+')'}}>
              <div className="clearfix choice choice_selected">
                <p className="alternativ_angebot_text">{st['bwh.angebote.different.room']}</p>
              </div>
              <div className="clearfix roomverlauf"></div>
            </div>
          </div>
        </div>
      </div>      
      );
    }
  }
}
export default Angebote;
