// https://docs.pci-proxy.com/docs/secure-fields
import React, {useEffect, useState} from 'react';
import { st, pt } from './texte'
import { calcPreisJetzt } from './common.js'
import Confirm from './Confirm'
import { InputFeld } from './Felder'
import moment from 'moment';
import {CardNumberElement, CardExpiryElement, CardCVCElement, injectStripe} from 'react-stripe-elements';
import { ReactComponent as PfeilRechtsFettIcon } from './img/pfeil_rechts_fett.svg'
import { ReactComponent as GutzeichenIcon } from './img/gutzeichen.svg'
import { ReactComponent as KreuzIcon } from './img/kreuz-01.svg'
import ccamex from './img/wpcc_amex.gif'
import ccdiners from './img/wpcc_diners.gif'
import ccmastercard from './img/wpcc_mastercard.gif'
import ccvisa from './img/wpcc_visa.gif'

const DatatransForm = ({ preis, appState, setAppState, konfig, setBooking, change, ...others}) => {
  const [valid, setValid] = useState({nummer: -1, cvc:0});
  const [secFields, setSecFields] = useState(null);

  const loadScript = () => new Promise(resolve => {
    const tag = document.createElement('script');
    tag.async = false;
    tag.src = "https://pay.datatrans.com/upp/payment/js/secure-fields-2.0.0.min.js";
    if (appState.paymentinfo.DatatransMerchantId == "1100006055") { // alixon-Test
      tag.src = "https://pay.sandbox.datatrans.com/upp/payment/js/secure-fields-2.0.0.js";
    }
    const body = document.body;
    body.appendChild(tag);
    tag.addEventListener('load', resolve, {
      once: true
    });
  });
  
  useEffect(() => {
    loadScript()
      .then( () => {
        /*
        var secureFields = new SecureFields();
        secureFields.initTokenize("1100007006", {
          cardNumber: "card-number-placeholder",
          cvv: "cvv-placeholder"
        });
        https://docs.pci-proxy.com/docs/secure-fields-initialization-and-styling
        */
        var payMeth = [];
        if (CCTypes.indexOf('V')>-1) {payMeth.push("VIS")} // Visa
        if (CCTypes.indexOf('M')>-1) {payMeth.push("ECA")} // Mastercard
        if (CCTypes.indexOf('A')>-1) {payMeth.push("AMX")} // AMEX
        if (CCTypes.indexOf('D')>-1) {payMeth.push("DIN")} // Diners
        //DIS  Discover
        //JCB  JCB
        //ELO  ELO
        //CUP  UnionPay

        const sF = new window.SecureFields();
        setSecFields(sF);
        const dtf = {
            cardNumber: "card-number-placeholder",
          };
        if (appState.paymentinfo.cvv>0) {
          dtf.cvv = "cvv-placeholder"; 
        }
        sF.initTokenize(appState.paymentinfo.DatatransMerchantId, dtf, {
            styles: {
                "*": "font-size: 1.1em; background-color: white; padding: 10px 5px;",
                "XcardNumber.valid": "background-color: #92D154;",
                "XcardNumber.empty": "background-color: #D38080;",
                "XcardNumber.invalid": "background-color: #D38080;",
                "Xcvv.valid": "background-color: #92D154;",
                "Xcvv.empty": "background-color: #D38080;",
                "Xcvv.invalid": "background-color: #D38080;",
            },
            paymentMethods: payMeth
          }
        );
        sF.on("change", (ev) => {
          const cvc = ev.fields.cvv?ev.fields.cvv:{};
          var cvcvalid = appState.paymentinfo.cvv==0?1:-1;
          if (appState.paymentinfo.cvv==1) {
            cvcvalid = cvc.length>0?(cvc.valid?1:-1):0;
          }
          else if (appState.paymentinfo.cvv==2) {
            cvcvalid = (cvc.length>0 && cvc.valid)?1:-1;
          }
          setValid((valid)=>{
            return {
              ...valid, 
              nummer:(ev.fields.cardNumber.valid?1:-1), 
              cvc:   cvcvalid
            }
          });
        });        
      });    
  }, []);
  
  const fieldChange = (elId) => (event) => {
    var v = event.target.value;
    change(elId, v);
  }

  const valid_inhaber = appState.cc_inhaber.length>0 ? 1:-1;
  const valid_expmonth = appState.cc_expmonth.length>0 && appState.cc_expmonth!=="0" ? 1:-1;
  const valid_expyear = appState.cc_expyear.length>0 && appState.cc_expyear!=="0" ? 1:-1;

  const submit = (ev) => {
    
    //console.log([valid.nummer, valid.cvc, valid_inhaber, valid_expmonth, valid_expyear, valid]);
    
    if (valid.nummer<1 || valid.cvc<0 || valid_inhaber<1 || valid_expmonth<1 || valid_expyear<1) {
      var fehlertext = st['bwh.formularzahlungsdaten.required.message'];
      setAppState({error: fehlertext+" (1)"});
      return;
    }
    
    var secParam = {expm: appState.cc_expmonth, expy: appState.cc_expyear};
    secFields.submit(secParam);
    secFields.on("success", function(data) {
      //console.log(data);
      if (data.transactionId) {
        change('number', data.transactionId);
        change('cvc', '');
        change('postalcode', '');
        setBooking();
      }
      /*
      else {
        console.log(2);
        console.log(data);
        var fehlertext = st['bwh.formularzahlungsdaten.required.message'];
        setAppState({error: fehlertext+" (2)"});
      }
      */
    }); 
    secFields.on("error", function(data) {
      //console.log(4);
      setAppState({error: data.error+" (4)"});
    }); 
    secFields.on("validate", function(data) {
      //console.log(3);
      //console.log(data);
      if (data.hasErrors) {
        var fehlertext = st['bwh.formularzahlungsdaten.required.message'];
        setAppState({error: fehlertext+" (3)"});
      }
    }); 
    /*
    $(function() {
      $("#go").click(function() {
        secureFields.submit({ // submit the "form"
          expm: 12,
          expy: 26
        });
      });
    });    
    secureFields.on("success", function(data) {
      if (data.transactionId) {
        // transmit data.transactionId and the rest
        // of the form to your server    
      }
    });   
    */
  }

  const PreisJetzt = calcPreisJetzt(appState);
  const jahrListe = [(<option value="0" key="0">-</option>)];
  var y = parseInt(moment().format('Y'));
  for(var i=0; i<=13; i++) {
    jahrListe.push(<option value={(y+i)%100} key={y+i}>{y + i}</option>);
  }
  const CCTypes = konfig.cctypes;
  
  return (
    <div>
      <div id="zahlungsdatenFormular_wrap">
        <div className="clearfix viewheader">
          <p id="zahlungsdatenFormular_headertext" className="viewheadertext">{st['bwh.zahlungsdaten.formular.headertext']}</p>
        </div>
        <div id="zahlungsdatenFormular_formular" className="clearfix formularansicht">
          <div className="formularlegende clearfix">
            <p id="zahlungsdatenFormularLegende" className="formularlegende-text">{st['bwh.zahlungsdaten.legende']}</p>
          </div>
        {CCTypes && CCTypes.length>0 &&
          <div className="ccimgs">
            {CCTypes.indexOf('V')>-1 &&  <img src={ccvisa} />}
            {CCTypes.indexOf('M')>-1 &&  <img src={ccmastercard} />}
            {CCTypes.indexOf('A')>-1 &&  <img src={ccamex} />}
            {CCTypes.indexOf('D')>-1 &&  <img src={ccdiners} />}
          </div>        
        }
          <div id="zahlungsdatenFormular_formular_datatrans" className="clearfix formularansicht">
            <InputFeld name="inhaber" titel={st['bwh.zahlungsdaten.formular.inhaber'].slice(0,-1)} value={appState.cc_inhaber} required={true}
              valid={valid_inhaber} change={fieldChange} />
            <div className="formularfeld clearfix" >
              <p className="formularfeld-label">{st['bwh.zahlungsdaten.formular.nummer']}</p>
              <div className="formularfeld-feld no-padding clearfix" >
                <div id="card-number-placeholder" titel={st['bwh.zahlungsdaten.formular.nummer'].slice(0,-1)} style={{height:"41px"}} />
              </div>
              {valid.nummer===1 &&
                <GutzeichenIcon className="formularfeld-gutzeichen image"  />
              }
              {valid.nummer===-1 &&
                <KreuzIcon className="formularfeld-kreuzzeichen image" />
              }
            </div>
            <div className="formularfeld clearfix">
              <p id="zahlungsdatenFormularAblaufLabel" className="formularfeld-label">{st['bwh.zahlungsdaten.formular.ablauf']}</p>
              <div className="formularfeld-feld no-padding ohnegutzeichen clearfix">
                <select className="formularfeld-feld formularfeld-halbfeld mitvalid required"
                  onChange={fieldChange('expmonth')}  value={appState.cc_expmonth} id="zahlungsdatenFormularExpMonth">
                  <option label="-" value="0">-</option>
                  <option label="01" value="01">01</option>
                  <option label="02" value="02">02</option>
                  <option label="03" value="03">03</option>
                  <option label="04" value="04">04</option>
                  <option label="05" value="05">05</option>
                  <option label="06" value="06">06</option>
                  <option label="07" value="07">07</option>
                  <option label="08" value="08">08</option>
                  <option label="09" value="09">09</option>
                  <option label="10" value="10">10</option>
                  <option label="11" value="11">11</option>
                  <option label="12" value="12">12</option>
                </select>
              {valid_expmonth===1 &&
                <GutzeichenIcon className="formularfeld-gutzeichen image halbfeld links" />
              }
              {valid_expmonth===-1 &&
                <KreuzIcon className="formularfeld-kreuzzeichen image halbfeld links" />
              }
                {valid_expyear===1?<GutzeichenIcon className="formularfeld-kreuzzeichen image halbfeld" />:
                  valid_expyear===-1?<KreuzIcon className="formularfeld-kreuzzeichen image halbfeld" />:null} 
                <select className="formularfeld-feld formularfeld-halbfeld mitvalid formularfeld-halbfeld-rechts required"
                  onChange={fieldChange('expyear')}  value={appState.cc_expyear} id="zahlungsdatenFormularExpYear" >
                  {jahrListe}
                </select>
              </div>
            </div>
          {appState.paymentinfo.cvv>0 &&
            <div id="zahlungsdatenFormularCVCFeld" className="formularfeld clearfix">
              <p id="zahlungsdatenFormularCVCLabel" className="formularfeld-label">
                {st['bwh.zahlungsdaten.formular.cvc'].slice(0,-1)+((appState.paymentinfo.cvv==2)?'*':'')}
              </p>
              <div className="formularfeld-feld no-padding ohnegutzeichen clearfix">
                <div className="formularfeld-feld no-padding formularfeld-feldcvc" >
                  <div id="cvv-placeholder" style={{height:"41px"}} />
                </div>
                {valid.cvc===1 &&
                  <GutzeichenIcon className="formularfeld-gutzeichen image"  />
                }
                {valid.cvc===-1 &&
                  <KreuzIcon className="formularfeld-kreuzzeichen image" />
                }
                <a href={"https://secure.dormproject.ch/online/templates/1_cvv_"+appState.sprache+".html"} id="zahlungsdatenFormularWocvcText" className="small" target="_blank" rel="noopener noreferrer" >
                  {st['bwh.zahlungsdaten.formular.wocvc']}
                </a>
              </div>
            </div>
          }
            <div id="formularfeld clearfix" className="formularfeld clearfix">
            {PreisJetzt > 0?
              <h3>{st['bwh.zahlungpreis'].replace('%{preis}', PreisJetzt.toFixed(appState.paymentinfo.decimal) + ' ' + appState.paymentinfo.waehrung)}</h3>
              :
              <h3>{st['bwh.zahlung0']}</h3>
            }
            </div>
          </div>
        </div>
      </div>
      <Confirm appState={appState} {...others}/>
      <div id="boo_now_leiste_unten" className="book-now-leiste-wrap clearfix">
        <div className="book-now-leiste clearfix handcursor" onClick={submit}>
          <div className="book-now-wrap clearfix book-now-wrap-hover">
            <p id="book_now_preis">{st['bwh.preis.total']} {preis}</p>
            <div className="book-now clearfix">
              <p id="book_now_text_unten" className="book-now-text">{pt.hostel_booknowfinish}</p>
              <PfeilRechtsFettIcon />
            </div>
          </div>
        </div>
      </div>
    </div>        
  );
}

export default DatatransForm;