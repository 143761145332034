import React from 'react';
import { st } from './texte'
import { ReactComponent as PfeilUntenIcon } from './img/pfeil_unten.svg'
import { ReactComponent as PfeilObenIcon } from './img/pfeil_oben.svg'
import { ReactComponent as PfeilRechtsIcon } from './img/pfeil_rechts.svg'
import { ReactComponent as PfeilLinksIcon } from './img/pfeil_links.svg'
import wellenBild from './img/wellen.svg'

class Kopf extends React.Component {
  setPopHeader = no => event => {
     this.props.setAppState((state) => ({
      pop_header: state.pop_header === no ? 0 : no
    }));    
  };
  setLogin = (feld) => event => {
    this.props.setAppState({["login_"+feld]:event.target.value}); 
  }
  clickLogin = event => {
    this.props.doVerifyLogin(0);
  }
  clickCheckin = clickCheckin => {
    this.props.doVerifyLogin(1);
  }
  switchSprache = (sp, spid) => event => {
    this.props.setAppState({sprache: sp, sprachId: spid, pop_header: 0});
    this.props.loadSprache(spid);
  };
  handleSliderClickRight = event => {
     this.props.setAppState((state) => ({
      sliderpos:  state.sliderpos < state.konfig.bilder.hostelbilder.length -1 ? state.sliderpos + 1 : 0
    }));    
  };
  handleSliderClickLeft = event => {
     this.props.setAppState((state) => ({
      sliderpos: state.sliderpos > 0 ? state.sliderpos - 1 : state.konfig.bilder.hostelbilder.length - 1
    }));    
  };

  render () {
    const { appState } = this.props;

    var errmessage = false;
    if (appState.changeerror) {
      const errtxt = ['', 'bwh.errormessage.loginfailed.resnotexist', 'bwh.errormessage.loginfailed.zuspaet', 
        'bwh.errormessage.loginfailed.emailfalsch', 'bwh.loginerror.resnr.invalid', 'bwh.loginerror.email.invalid' ];
      errmessage = st[errtxt[appState.changeerror]]; 
    }
    
    return (
      <React.Fragment>
        <div id="sprachenContainer" className="clearfix">
          <div id="sprachauswahl" className="clearfix" > 
            <div id="button_login" className="clearfix" onClick={this.setPopHeader(1)}>
              <p id="button_login_text">{appState.konfig.hascheckin?st['bwh.loginbuttoncheckin']:st['bwh.loginbutton']}</p>
              {appState.pop_header===1?<PfeilObenIcon />:<PfeilUntenIcon />}
            </div>
    {appState.konfig.sprachen.length>1?
            <div id="button_sprache" className="clearfix" onClick={this.setPopHeader(2)}>
              <p id="button_sprache_text">{appState.konfig.sprachen_key[appState.sprache]}</p>
              {appState.pop_header===2?<PfeilObenIcon />:<PfeilUntenIcon />}
            </div>
    :null}
          </div>
    {appState.pop_header===1?
          <div id="login_klappe" className="clearfix">
            <input type="text" id="login_data_resnr" className="loginbereich-feld" value={appState.login_resnr} onChange={this.setLogin('resnr')} placeholder={st['bwh.loginfeld.resnr']}/>
            <input type="email" id="login_data_email" className="loginbereich-feld" value={appState.login_email} onChange={this.setLogin('email')} placeholder={st['bwh.loginfeld.email']}/>
      {appState.konfig.hascheckin?
            <div id="bu_checkin" className="button_dologin clearfix" onClick={this.clickCheckin}>
              <p>{st['bwh.checkinbutton']}</p>
            </div>
      :null}            
            <div id="button_dologin" className="button_dologin clearfix" onClick={this.clickLogin}>
              <p>{st['bwh.dologinbutton']}</p>
            </div>
      {appState.changeerror &&
            <p id="button_dologin_errortext">{errmessage}</p>        
      }
          </div>
    :null}            
    {appState.pop_header===2?
          <div id="sprachauswahl_klappe" className="clearfix">
            <div id="sprachbuttons" className="clearfix">
      {appState.konfig.sprachen.map((el, k) => (
              <div id={"sprache_abk_"+el.abk} onClick={this.switchSprache(el.abk, el.sprache_id)} key={k} className={'sprache sprachelen_'+Math.min(5, el.bezeichnung.length)+(el.abk===appState.sprache?" gewaehlt":"")}>
                <p>{el.bezeichnung}</p>
              </div>
      ))}        
            </div>
          </div>
    :null}            
        </div>
     {!appState.get.noheadimg &&       
        <div id="hostelPresentationContainer" className="clearfix" >
          <div id="hostel" className="clearfix" style={{backgroundImage:'url('+appState.konfig.bilder.hostelbilder[appState.sliderpos]+')'}}>
            <div id="farbflaeche" className="clearfix"> 
            </div>
          </div>
          <div id="wellen" className="clearfix" style={{backgroundImage:'url('+wellenBild+')'}}> 
          </div>
          <div id="slider" className="clearfix">
            <div id="sliderinhalt" className="clearfix">
              <div id="pfeile" className="clearfix">
                <PfeilLinksIcon onClick={this.handleSliderClickLeft} />
                <PfeilRechtsIcon onClick={this.handleSliderClickRight} />
              </div>
              <p id="hostelname">{appState.konfig.hostelname}</p>
            </div>
          </div>
        </div>
     }
      </React.Fragment>
    );
  }
}

export default Kopf