import React from 'react';
import { getGenderText, st, pt, replaceRateText } from './texte'
import { findRate, textileConvert, constants } from './common'
import PaymentTable from './PaymentTable';

const DisplayAngebot = (props) => {
    const {appState, teststyle, moment, dateFormat, rabatt, total, total_service, ohnerabatt, paynow, konfig, offen, specialdays } = props;
    const anglist = appState.bookingData.angebot.list
    const payList = anglist[0].PaymentList;
    const zeitformat = parseInt(appState.sprachId)===1?"DD.MM.YYYY HH:mm:ss":"MMMM Do YYYY, HH:mm:ss a";
    const pp = anglist[0].paymentPoints;
    var last = 0;
    var points = [];
    if (appState.selectedRate) {
      for(var i = 0; i<pp[appState.selectedRate].length; i++) {
        var point = pp[appState.selectedRate][i];
        var p = parseFloat(point.preis);
        if (point.preis || point.dateText==='bwh.checkin') {
          var text = '';
          if (point.dateText==='bwh.checkin') {
            text = st['bwh.zahlung.zahlbar']+' '+st['bwh.checkin2'];
          }
          else if (point.dateText==='bwh.jetzt') {
            text = st['bwh.zahlung.zahlbar']+' '+st['bwh.jetzt'];
          } else {
            text = st['bwh.zahlung.dazwischen'];
          }
          points.push({text:text, preis:p - last});
          last = p;
        }
      }
    }
    var ratedetails = false;
    var ratehtml = false;
    var canceltext = false;
    if (['personendaten', 'zahlungNostripe', 'zahlungStripe', 'change'].indexOf(appState.status) > -1) {
      const rate = findRate(anglist[0].rates, appState.selectedRate);
      if (rate !== false && rate.rate_id > 0) {
        ratehtml = rate.details; 
        canceltext = anglist[0].canceltext?anglist[0].canceltext:false;
        ratehtml = replaceRateText(ratehtml, moment, dateFormat);
        canceltext = replaceRateText(canceltext, moment, dateFormat);
        ratedetails = rate.allpoints;
      }
    }
    
    for (i = 0; i<anglist.length;i++) {
      // Vereinfachung: der Rabatt wird pauschal den Erwachsenen des ersten Zimmers angerechnet
      var rab = anglist[i].rabatt;
      for (var j = 0; j<anglist[i].rooms.length;j++) {
        var ag = anglist[i];
        var room = ag.rooms[j];
        var roomtext = '';
        var akgroup = {};
        var ak = 0;
        for (var k=0; k<appState.konfig.alterskats.length;k++) {
          ak=appState.konfig.alterskats[k];
          if (room.aklist[ak]) {
            var desc = ak===appState.konfig.childalterskat?
            st['bwh.buchungsdaten.kinderselect.singular'] + '/ ' + st['bwh.buchungsdaten.kinderselect.plural']:
            st['bwh.buchungsdaten.erwachseneselect.singular'] + '/ ' + st['bwh.buchungsdaten.erwachseneselect.plural'];
            if (pt.agecategory_description && pt.agecategory_description[ak]) {
              var desc = pt.agecategory_description[ak];
            }
            if (!akgroup[desc]) akgroup[desc] = {anz:0, preis:0};
            akgroup[desc].anz += room.aklist[ak].anz;
            akgroup[desc].preis += room.aklist[ak].preis;
          }
        }
        for (var akt in akgroup) {
          var d = akt.split('/');
          var akg = akgroup[akt];
          roomtext += akg.anz + ' ';
          roomtext += (d[1] && akg.anz>1?d[1]:d[0]);
          if (akg.preis > 0) {
            roomtext += ' x ' + ((akg.preis - rab/ag.dauer) / akg.anz).toFixed(appState.paymentinfo.decimal);
            rab = 0;
          }
          roomtext += ', ';
        }
        
        roomtext += ag.dauer+' '+st['bwh.buchungsdaten.naechteselect.'+(ag.dauer===1?'singular':'plural')];
        anglist[i].rooms[j].roomtext = roomtext;
      }
    }

    var paydisplay = 'nichts';     // zB villa
    if (payList) paydisplay = 'paylist';
    else if (paynow>0) paydisplay = 'now/outstanding';
    else if (appState.selectedRate && !appState.bookingData.isBooked) paydisplay = 'payment points';
    
    return (
    <React.Fragment>
      <div>
      {canceltext?
        <h2 style={{textAlign:'center',marginTop: 0}}>{canceltext}</h2>
      :null}
      </div>
      <div id="gewaehltesangebot_wrap" className="filterbereich-wrap clearfix" style={teststyle}>
        <div className="filterbereich clearfix">
          <div className="filterbereich-titel">
            <p className="filterbereich-titeltext">
              {(appState.bookingData && appState.bookingData.isBooked)?st['bwh.confirmemail.buchung.titel']:st['bwh.gewaehltesangebot.bereichlabel']}
            </p>
          </div>
          <div className="clearfix bereichinhalt-wrap">
      {(appState.bookingData.angebot.list[0].resNr)? 
            <div id="DispAngebotResNr">
              <div className="gewaehltesangebot-reservationsdaten-zeile clearfix">
                <p className="reservationsdaten-text-titel">{st['bwh.reservation.resnr']}</p>
                <p className="reservationsdaten-text-text">{appState.bookingData.angebot.list[0].resNr}</p>
              </div>
              <div className="gewaehltesangebot-reservationsdaten-zeile clearfix">
                <p className="reservationsdaten-text-titel">{st['bwh.personendaten.formular.headertext']}</p>
                <p className="reservationsdaten-text-text">{appState.bookingData.angebot.list[0].gastInfo}</p>
              </div>
            </div>
      :null}
      {appState.bookingData.angebot.list.map((el, k)=> (        
            <React.Fragment key={k}>
            <div>
              <div className="gewaehltesangebot-reservationsdaten-zeile clearfix">
                <p className="reservationsdaten-text-text">{moment(el.ankunft).format(dateFormat)} - {moment(el.abreise).format(dateFormat)} ({el.naechte} {st['bwh.buchungsdaten.naechteselect.'+(el.naechte===1?'singular':'plural')]})</p>
              </div>
    {specialdays!==false &&
              <table style={{margin: "10px 0 10px 0"}}><tbody>
                {specialdays}    
              </tbody></table>
    }
            </div>
            <div style={{borderBottom: "2px solid #FFF", paddingBottom: "1em"}}>
        {el.rooms.map((room, j)=> (        
              <div className="gewaehltesangebot_element_wrap clearfix" key={j}>
                <div className="gewaehltesangebot_imagebereich clearfix">
                  <div className="gewaehltesangebot_image clearfix" style={{backgroundImage:"url("+appState.konfig.bilder.preiskat[room.pkat]+")"}}>
                  </div>
                </div>
                <div className="gewaehltesangebot_beschrieb clearfix">
                  <div className="gewaehltesangebot_beschriebtitel clearfix">
                    <p>{pt.preiskat_bezeichnung[room.pkat] + getGenderText(room.geschlecht, konfig.hideprivatmarker)}</p>
                  </div>
                  <div className="gewaehltesangebot_beschriebtext clearfix">
                    <p dangerouslySetInnerHTML={{__html: textileConvert(pt.preiskat_langtext[room.pkat]?pt.preiskat_langtext[room.pkat]:pt.preiskat_kurztext[room.pkat])}} />
                    <p><br />{room.roomtext}</p>
                  </div>
                </div>
                <div className="gewaehltesangebot_preis">
                  <p>{(room.roompreis).toFixed(appState.paymentinfo.decimal)+' '+appState.paymentinfo.waehrung}</p>
                </div>
              </div>
        ))}
            </div>
        {(el.services && el.services.length>0) &&
            <div className="preis_wrap">
          {el.services && el.services.map((z, j)=> (
              <div key={j}>
            {appState.zusatzAnzahl[z.zkat] > 0 &&                                                  
              <React.Fragment>
              <div className="gewaehltesangebot_imagebereich clearfix" style={{height:'1em'}} />
              <div className="gewaehltesangebot_beschrieb clearfix">
              {z.def_type === constants.zusatzNetto || z.def_type === constants.zusatzAvgN?
                <p>{pt.zusatz_bezeichnung[z.zkat]+" ("+(Math.abs(z.preis)===1?'':(Math.round((z.preis*100+ Number.EPSILON) * 100) / 100)+"% "+st['bwh.zusatz.von']+" ")+appState.zusatzAnzahl[z.zkat].toFixed(appState.paymentinfo.decimal)+")"}</p>
              :
                <p>{appState.zusatzAnzahl[z.zkat]+"x "+pt.zusatz_bezeichnung[z.zkat]+" ("+appState.zusatzAnzahl[z.zkat]+" x "+z.preis.toFixed(appState.paymentinfo.decimal)+")"}</p>
              }
              </div>
              <div className="gewaehltesangebot_preis">
                <p>{(appState.zusatzAnzahl[z.zkat] * z.preis).toFixed(appState.paymentinfo.decimal)+' '+appState.paymentinfo.waehrung}</p>
              </div>
              </React.Fragment>
            }
              </div>
          ))}
            </div>
        }
        {(findRate(el.rates, appState.selectedRate).preis.commission && appState.konfig.pricenocomm && appState.konfig.pricenocomm!==2)?
            <div className="preis_wrap">
              <div className="gewaehltesangebot_imagebereich clearfix" style={{height:'1em'}} />
              <div className="gewaehltesangebot_beschrieb clearfix">
                <p>{st['bwh.preis.kommision']}</p>
              </div>
              <div className="gewaehltesangebot_preis">
                <p>{(findRate(el.rates, appState.selectedRate).preis.commission).toFixed(appState.paymentinfo.decimal)+' '+appState.paymentinfo.waehrung}</p>
              </div>
            </div>
        :null}
            </React.Fragment>
      ))}
          </div>
        </div>
        <div className="filterbereich clearfix">
          <div className="filterbereich-titel">
            <p className="filterbereich-titeltext">{st['bwh.totalpreis.bereichlabel']}</p>
          </div>
          <div className="clearfix bereichinhalt-wrap">
            <div className="bereichinhalt">
              <div id="totalpreistext_wrap">
                <p className="reservationsdaten-text-text">
                  {rabatt<0 ? (
                    <span>{st['bwh.preis.rabattcode']}: <b>{appState.filterCoupon}</b> {rabatt.toFixed(appState.paymentinfo.decimal)} {appState.paymentinfo.waehrung}<br/></span>
                  )
                  :(<span>&nbsp;</span>)}
                </p>
      {ratedetails !== false &&
                <PaymentTable appState={appState} ratedetails={ratedetails} moment={moment} dateFormat={dateFormat} paynow={paynow} />
      }
              </div>
              <div>
                <div id="totalpreis">
      {rabatt<0 && 
                  <p id="totalpreis_ohnecoupon">{ohnerabatt.toFixed(appState.paymentinfo.decimal)}</p>
      }
                  <p id="totalpreis_waehrung">{appState.paymentinfo.waehrung}</p>
                  <p id="totalpreis_betrag">{total.toFixed(appState.paymentinfo.decimal)}</p>
                </div>
              </div>
      {paydisplay === 'payment points' ?
              <React.Fragment>
        {points.map((point, k) => (
              <div id="totalpreistextanzahlung_wrap" className="totalpreischarge-wrap" key={k}>
                <p id="totalpreistextanzahlung_text" className="totalpreischarge-text">{point.text}</p>
                <div id="totalpreisanzahlung" className="totalpreischarge-price">
                  <p id="totalpreisanzahlung_waehrung" className="totalpreischarge-waehrung">{appState.paymentinfo.waehrung}</p>
                  <p id="totalpreisanzahlung_betrag" className="totalpreischarge-betrag">{point.preis.toFixed(appState.paymentinfo.decimal)}</p>
                </div>
              </div>
        ))}
              </React.Fragment>
      :null}
      {paydisplay === 'paylist' ?
              <React.Fragment>
        {payList.map((el, k) => (
              <div id="totalpreistextanzahlung_wrap" className="totalpreischarge-wrap" key={k}>
                <p id="totalpreistextanzahlung_text" className="totalpreischarge-text">
          {el.type==='card'?
                <span>{st['bwh.totalpreis.anzahlung']+' @ '+ moment(el.time).format(zeitformat)}</span>
          :
                <span>{st['bwh.totalpreis.bezahlt']}</span>
          }
                </p>
                <div id="totalpreisanzahlung" className="totalpreischarge-price">
                  <p id="totalpreisanzahlung_waehrung" className="totalpreischarge-waehrung">{appState.paymentinfo.waehrung}</p>
                  <p id="totalpreisanzahlung_betrag" className="totalpreischarge-betrag">{(-parseFloat(el.amount)).toFixed(appState.paymentinfo.decimal)}</p>
                </div>
              </div>
        ))}
              <div id="totalpreistextlater_wrap" className="totalpreischarge-wrap">
                <p id="totalpreistextlater_text" className="totalpreischarge-text">{st['bwh.totalpreis.later']}</p>
                <div id="totalpreislater" className="totalpreischarge-price">
                  <p id="totalpreislater_waehrung" className="totalpreischarge-waehrung">{appState.paymentinfo.waehrung}</p>
                  <p id="totalpreislater_betrag" className="totalpreischarge-betrag">{(total-appState.bookingData.angebot.list[0].paytotal).toFixed(appState.paymentinfo.decimal)}</p>
                </div>
              </div>
              </React.Fragment>
      :null}
      {paydisplay === 'now/outstanding' ?
              <React.Fragment>
              <div id="totalpreistextanzahlung_wrap" className="totalpreischarge-wrap">
                <p id="totalpreistextanzahlung_text" className="totalpreischarge-text">{st['bwh.totalpreis.paynow']}</p>
                <div id="totalpreisanzahlung" className="totalpreischarge-price">
                  <p id="totalpreisanzahlung_waehrung" className="totalpreischarge-waehrung">{appState.paymentinfo.waehrung}</p>
                  <p id="totalpreisanzahlung_betrag" className="totalpreischarge-betrag">{(-paynow).toFixed(appState.paymentinfo.decimal)}</p>
                </div>
              </div>
              <div id="totalpreistextlater_wrap" className="totalpreischarge-wrap">
                <p id="totalpreistextlater_text" className="totalpreischarge-text">{st['bwh.totalpreis.later']}</p>
                <div id="totalpreislater" className="totalpreischarge-price">
                  <p id="totalpreislater_waehrung" className="totalpreischarge-waehrung">{appState.paymentinfo.waehrung}</p>
                  <p id="totalpreislater_betrag" className="totalpreischarge-betrag">{(offen).toFixed(appState.paymentinfo.decimal)}</p>
                </div>
              </div>
              </React.Fragment>
      :null}
      
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
export default DisplayAngebot;
