import { logtext } from './Interface.js'

const constants = {
  zusatzTypJaNein: 1,
  zusatzTypAnzahl: 2,
  zusatzTypFix: 3,
  
  zusatzAnz1: 0,
  zusatzAnzN: 1,
  zusatzAnzP: 2,
  zusatzAnzNP: 3,
  zusatzNetto: 4,
  zusatzAvgN: 5,
  
  PersDateNichtAnzeigen: 1,
  PersDataAnezgen: 2,
  PersDataObligatorisch: 3,
  PersDataObligOhneKK: 4,
  
  PersFVorname: 1,
  PersFName: 2,
  PersGebDat: 4,
  PersFAdresse: 12,
  PersFPLZ: 14,
  PersFOrt: 15,
  PersFLand: 3,
  PersFPassLand: 5,
  PersFEmail: 16,
  PersFEmail2: 17,
  

  bfStripe:         'Stripe',
  bfLocalcheck:     'localCheck',
  bfNoPaymentInfo:  'noPaymentInfo',
  bfDatatransToken: 'datatransToken',
}

const validateEmail = (email) => {
  var re = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
  return re.test(email);
}

const textileConvert = (text) => {
  if (!text) return '';
  var t = window.textile(text);
  t = t.replace(/<a href=/g, '<a target="_blank" href=');
  t = t.replace(/<table>/g, '<table class="bwhtable">');
  t = t.replace(/<span class="caps">(.[^</span>]*)<\/span>/g, '$1');
  t = t.replace(/<\/?del>/g, '');
  return t;
};

const getTitelFromText = (s) => {
  var titel = '';
  var h1 = s.indexOf('h1. ');
  if (0 <= h1) {
    var nl = s.indexOf("\n", h1);
    if (0 <= nl) {
      titel = s.substr(h1+4, nl-h1-4);
    }
    else {
      titel = s.substr(h1+4);
    }
  }
  
  return textileConvert(titel);
};

const getTextFromText = (s) => {
  var text = '';
  var h1 = s.indexOf('h1. ');
  if (0 <= h1) {
    var nl = s.indexOf("\n", h1);
    if (0 <= nl) {
      text = s.substr(nl+1);
    }
  }
  else {
    text = s;
  }
  
  return textileConvert(text);
};

const startPiwik = () => {
  window._paq = window._paq || [];
  window._paq.push(['trackPageView']);
  window._paq.push(['enableLinkTracking']);
  (function() {
    var u="https://webstats.alixon.ch/";
    window._paq.push(['setTrackerUrl', u+'matomo.php']);
    window._paq.push(['setSiteId', '8']);
    var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
    g.type='text/javascript'; g.async=true; g.defer=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
  })();
}

const sendAnalyticsEvent = (appState, event) => {
  const analyticsKey = appState.konfig.analyticsKey;
  if (!isNaN( analyticsKey) && typeof window.Matomo !== 'undefined') {
    var tracker = window.Matomo.getTracker('https://webstats.alixon.ch/piwik.php', analyticsKey);
    tracker.trackEvent('step', event); //211
  }
  if (typeof window.gtag != 'undefined') {  //UA-101622297-1
    window.gtag('event', event);
  }
  
  logtext(appState, 'eventlog: '+event);
  
}

const sendAnalyticsPage = (appState, page) => {
  if (typeof window.Matomo !== 'undefined') {
    var targetPage = 'BWH-react/' + page;
    if (appState.is_test) {
      targetPage = 'dev/' + targetPage;
    }
    var tracker = window.Matomo.getTracker('https://webstats.alixon.ch/piwik.php', 8);
    tracker.trackPageView(targetPage);
  }
}

/*
const sendAnalyticsGoal = (appState, goal) => {
      var goalLanguage = '';
      switch (bwhApp.bwhData.sprache) {
        case 'de': goalLanguage=11; break;
        case 'fr': goalLanguage=12; break;
        case 'es': goalLanguage=13; break;
        case 'pt': goalLanguage=14; break;
        case 'is': goalLanguage=15; break;    

}
*/

const scrollToElement = (elementId, smallWidthElementId, offset, anim, scrollback) => {
  var element = 'undefined';
  if (window.innerWidth > 900) {
    element = document.getElementById(elementId);
  }
  else {
    element = document.getElementById(smallWidthElementId);
  }
  var timer;
  if (element) {
    var scrollYTarget = element.offsetTop - offset;
    var scrollY = window.scrollY;
    if (anim) {
      if (20 < scrollYTarget-scrollY) {
        timer = setInterval(function () {
          scrollY += 20;
          if (scrollYTarget < scrollY) {
            clearInterval(timer);
          }
          window.scrollTo(0, scrollY);
        }, 20);
      }
      else if (-20 > scrollYTarget-scrollY && scrollback) {
        timer = setInterval(function () {
          scrollY -= 20;
          if (scrollYTarget > scrollY) {
            clearInterval(timer);
          }
          window.scrollTo(0, scrollY);
        }, 20);
      }
    }
    else {
      var scroll = true;
      if (!scrollback && scrollYTarget < scrollY) {
        scroll = false;
      }
      if (scroll) {
        window.scrollTo(0, scrollYTarget);
      }
    }
  }
};

const findRate = (rates, id) => {
  if (!id) { id = 0 };
  for (var i=0;i<rates.length;i++) {
    if (rates[i].rate_id === id)
      return rates[i];
  }
  for (i in rates) {
    return rates[i];
  }
  return false;
}

const calcPreisJetzt = (appState) => {
  return parseFloat(findRate(appState.bookingData.angebot.list[0].rates, appState.selectedRate).preis.payNow);
}

export { constants, validateEmail, textileConvert, getTitelFromText, getTextFromText, startPiwik, 
  sendAnalyticsEvent, sendAnalyticsPage, scrollToElement, findRate, calcPreisJetzt }