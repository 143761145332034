/*
? .nobreak { page-break-inside: avoid; }

Redirect wenn andere bwh_domain
Range auf Max Tage beschränken
Res ändern: Gleiche Anreise/Dauer zusammenfassen

Löschen Altes BWH:
- Widget migrieren


*/
import 'react-app-polyfill/ie9';
import React from 'react';
import { scrollToElement, findRate, calcPreisJetzt } from './common.js'
import { loadKonfigs, loadSprache, getBookingdata, getAngebote, setBooking, verifyLogin, cancelReservation, logtext, checkCode } from './Interface.js'
import Kopf from './Kopf'
import BackNext from './BackNext'
import SuchFilter from './SuchFilter'
import Angebote from './Angebote'
import DisplayAngebot from './DisplayAngebot'
import Rates from './Rates'
import Leistungen from './Leistungen'
import Personendaten from './Personendaten'
import CCDatenNoStripe from './CCDatenNoStripe'
import DatatransForm from './DatatransForm'
import StripeForm from './StripeForm'
import ConfirmInfo from './ConfirmInfo'
import OnlineCheckin from './OnlineCheckin'
import {Elements, StripeProvider} from 'react-stripe-elements';
import Fuss from './Fuss'
import bwh_version from './version'
import './App.css'
import { st, pt, getGenderText } from './texte'
import { constants, textileConvert, sendAnalyticsEvent, sendAnalyticsPage } from './common';
import LoadingIcon from './img/loading.svg';
import TextBgBild from './img/testreact_background.png'
import debounce from 'lodash.debounce';
import 'whatwg-fetch'

import Moment from 'moment';
import { extendMoment } from 'moment-range';
const moment = extendMoment(Moment);
require('moment/locale/de');
require('moment/locale/fr');
require('moment/locale/es');
require('moment/locale/pt');
require('moment/locale/is');
require('moment/locale/pl');
require('moment/locale/cs');
moment.locale('en');
var history = window.history;
//npm install whatwg-fetch --save
//npm install react-daterange-picker
//npm install moment moment-range

// https://www.npmjs.com/package/react-helmet

class App extends React.Component {
  
  componentDidMount = () => {
    Number.isInteger = Number.isInteger || function(value) {
    return typeof value === "number" && 
           isFinite(value) && 
           Math.floor(value) === value;
    };

    var intervalId = setInterval(this.handleSliderClickRight, 5000);
    this.setState({intervalId: intervalId});
    onpopstate = oEvent => {
      if (oEvent.state && oEvent.state.status) {
        if (oEvent.state.status !== 'gebucht') {
          this.setState({status:oEvent.state.status})
        }
      } else {
        this.setState({status:'angebot'})
      }
    };
  }
  componentWillUnmount = () => {
    clearInterval(this.state.intervalId);
  }
  
  constructor(props) {
    super(props);
    
    var h = window.location.hostname;    
    h = h.substr(0, h.indexOf('.'));
    
    var parts = window.location.search.substr(1).split("&");
    var get = {};
    for (var i = 0; i < parts.length; i++) {
      var temp = parts[i].split("=");
      get[decodeURIComponent(temp[0])] = decodeURIComponent(temp[1]);
    }
    if (get.arrival) {
      get.ankunft=get.arrival;
    } 
    if (get.duration) {
      get.dauer=get.duration;
    } 
    if (get.numOfGuests) {
      get.personen=get.numOfGuests;
    } 
    var pw = {};
    if (window.postvars) {
      pw = JSON.parse(window.postvars);
      if (pw.bwhwidget_ankunft) {
        get.ankunft=pw.bwhwidget_ankunft;
      }
      if (pw.bwhwidget_abreise) {
        get.dauer=moment(pw.bwhwidget_abreise).diff(moment(get.ankunft), 'days');
      }
      if (pw.bwhwidget_personen) {
        get.personen=pw.bwhwidget_personen;
      }
      if (pw.bwhwidget_coupon) {
        get.coupon=pw.bwhwidget_coupon;
      }
    }
    this._personendaten = React.createRef();
    this._CCNoStripe = React.createRef();

    if (!h) {
      h = 'test';
    }
    if (h === 'testreact') {
      h = 'test';
    }
    if (h === 'demoreact') {
      h = 'demo';
    }
    
    var pop_calendar = true;
    var ankunft = moment();
    if (get.ankunft) {
      ankunft = moment(get.ankunft);
      pop_calendar = false;
    }
    var host = window.location.hostname;
    
    var lang = get.lang?get.lang:(navigator.language || navigator.userLanguage);
    lang = lang.toLowerCase().replace(/[^a-z]/, '').substr(0, 2);
    const languages = {de:1,en:2,fr:3,es:4,pt:9,is:18,pl:20,cs:22};
    if (!languages[lang]) {
      lang = 'en';
    }
    var apihost = 'bwhapi.book.world';
    var m = host.match(/bwhdormdev([0-9]+)/);
    if (m) {
      apihost = 'bwhapi.dev'+m[1]+'.book.world';
    }
    else if (host.indexOf('bwhautotest')>-1) {
      apihost = 'bwhapi.autotest.book.world';
    }
    else if (host==='localhost') {
      apihost = 'bwhapi.dev'+ window.location.port.substr(-1)+'.book.world';
    }

    this.state = {
      is_test: false,
      apihost: apihost,
      host: window.location.hostname,
      get: get,
      hostelident: h,
      bookflag: get.book?get.book:'',
      bookNoCC: false,
      sessionid: 0,
      fromWidget: (pw.bwhwidget_ankunft && pw.bwhwidget_abreise && pw.bwhwidget_personen && pw.bwhwidget_coupon) || get.fromwidget,

      status : 'angebot',
      needZahlungsData : true,
      error: false,
      changeerror: false,
      servererror: false,
      startagent: '',
      priceagentCode: get.agent?get.agent:'DormOnline',
      resnr_changing: 0,
      resnr_booked: 0,
      justBooked: false,
      first: 1,
      konfig: {},
      selectedAngebotId: '',
      selectedRate: 0,
      zusatzAnzahl: {},
      sprache: lang,
      sprachId: languages[lang],
      sliderpos: 0, 
      pop_header: 0,
      pop_calendar: pop_calendar,
      openres: get.res?get.res:(!!get.resid && !!get.email),
      checkin: get.checkin?1:0,
      
      paymentinfo: {},
      avpreview: {},
      dormresults: false,
      hasRates: false,
      availRates: 0,
      minstay: 1,
      nofilter: false,

      bookingData: {},
      selBooking: false,
      
      login_resnr: get.resid?get.resid:'',
      login_email: get.email?get.email:'',
      rescrypt: '',
      
      zusatzleistungen: false,
      
      minDate: new Date(),
      filterAnkunft: ankunft,
      filterNaechte: get.dauer?parseInt(get.dauer):1,
      filterErwachsene: get.personen?parseInt(get.personen):false,
      filterKind: 0,
      filterKind2: 0,
      filterGeschlecht: [],
      filterMultiroomfilter: {},
      filterCoupon: get.code?get.code:(get.coupon?get.coupon:""),
      filterTicked: {},
      filterHash: '',
      validCode: false,
      
      stripeToken: '',
      cancelTimeLang: '',
      cancelText: '',
      bookConfirmText: '',
      renderid: 0,
      overlay: false,
      calendarDateRanges: [],
      cc_inhaber: '',
      cc_expmonth: '0',
      cc_expyear: '0',
    }
    if (host==='localhost' || apihost === 'bwhapi.dev2.book.world') {
      this.state.login_resnr = '71390';
      this.state.login_email = 'test@dormproject.ch';

      this.state.pf_1 = 'Andreas';
      this.state.pf_2 = 'Test';
      this.state.pf_3 = '1';
      this.state.pf_5 = '1';
      this.state.pf_12 = 'Aarepark 2a';
      this.state.pf_14 = '5000';
      this.state.pf_15 = 'Aarau';
      this.state.pf_16 = 'test@dormproject.ch';
      this.state.pf_17 = 'test@dormproject.ch';
      this.state.pf_zArrivalTime = '09:00';
      
      //this.state.cc_inhaber = 'Test Buchung';
      //this.state.cc_number = '4242 4242 4242 4242';
      //this.state.cc_expmonth = '12';
      //this.state.cc_expyear = moment().format('YY');
      //this.state.cc_cvc = '123';
      //this.state.cc_postalcode = '90012';
    }
    
    this.doCheckCode = debounce(this.doCheckCode, 500);
    if (this.state.filterCoupon) {
      this.doCheckCode();
    }
    loadKonfigs(this.state, this.setAppState, this.konfigLoaded, this.loadSprache);
  };
// Interface  
  konfigLoaded = () => {
    if (this.state.openres) {
      if (this.state.openres===true) {
        verifyLogin(this.state, this.setAppState, this.getBookingdata);
      }
      else {
        this.setState((state)=>({rescrypt:state.openres}), this.getBookingdata);
      }
    }
    else {
      this.getAngebote();
    }
  }
  reloadKonfigs = () => {
    loadKonfigs(this.state, this.setAppState, null, null);
  }
  reloadKonfigs2 = () => {
    loadKonfigs(this.state, this.setAppState, this.konfigLoaded, null);
  }
  setAppState = (mod, callback) => {
    this.setState(mod, callback); 
  }
  setNextStatus = () => {
    this.setState((state)=>({ status:state.nextstatus })); 
  }
  doVerifyLogin = (checkin) => {
    this.setState({justBooked: false, checkin:checkin});
    verifyLogin(this.state, this.setAppState, this.getBookingdata);
  }
  loadSprache = (spid) => {
    loadSprache(this.state, this.setAppState, spid);
  }
  getBookingdata = (rescrypt) => {
    getBookingdata(this.state, this.setAppState, rescrypt, this.reloadKonfigs);
  }
  getAngebote = () => {
    getAngebote(this.state, this.setAppState);
  }
  setBooking = async (token) => {
    return await setBooking(this.state, this.setAppState, token, this.getBookingdata);
  }
  logtext = (text) => {
    return logtext(this.state, text);
  }
  
// GUI Event Handlers  
  changeCode = event => {
    this.setState({filterCoupon:event.target.value})
    this.doCheckCode();
  };        
  doCheckCode = () => {
    checkCode(this.state, this.setAppState, this.getAngebote, this.reloadKonfigs2);
  }
  
  checkHasServices = (serv) => {
    if (!serv || serv.length===0) return false;
    for (var i=0;i<serv.length;i++) {
      var z = serv[i];
      if (z.notonbwh !== 1 && (pt.zusatz_bezeichnung[parseInt(z.zkat)]!=='NotActive')) {
        return true;
      }
    }
    return false;
  }

  
  trackPage = () => {
    const states = {
      angebot: 'Booking-1-Angebote',
      zusatzleistungen: 'Booking-2-Zusatz',
      personendaten: 'Booking-3-PersData',
      zahlungNostripe: 'Booking-4-Zahlung/localCheck',
      zahlungDatatrans: 'Booking-4-Zahlung/datatransToken',
      zahlungStripe: 'Booking-4-Zahlung/Stripe',
      canceled: 'Booking-5-Storno',
      change: 'Booking-9-ShowBooking',
    }
    if (states[this.state.status]) {
       sendAnalyticsPage(this.state, states[this.state.status]);
    }
  }
  
  clickNext = event => {
    var status = this.state.status;
    const ang = this.state.selBooking.angebot.list[0];
    var statusneu = '';
    if (status==='angebot' && this.state.selectedAngebotId !== '') {
      this.selectAngebotId(this.state.selectedAngebotId);
    }
    else if (status==='zusatzleistungen') {
      sendAnalyticsEvent(this.state, '02_Additional-Selected');
      statusneu = 'personendaten';
      if (this.state.availRates > 1) {
        statusneu = 'rates';
      }
    }
    else if (status==='rates') {
      statusneu = 'personendaten';
    }
    else if (status==='personendaten') {
      if (this._personendaten.current.checkAllFields()) {
        sendAnalyticsEvent(this.state, '03_GuestData-Entered');
        if (this.state.paymentinfo.BookingFee !== 'noPaymentInfo') {
          const PreisJetzt = calcPreisJetzt(this.state);
          if (this.state.paymentinfo.BookingFee===constants.bfDatatransToken) {
            if (PreisJetzt<0.1) {
              statusneu = 'zahlungDatatrans';
            }
            else {
              statusneu = 'zahlungStripe';
            }
          }
          else if (this.state.paymentinfo.BookingFee===constants.bfStripe || this.state.paymentinfo.BookingFee===constants.bfLocalcheck) 
          {
            if ((this.state.paymentinfo.BookingFee===constants.bfLocalcheck && PreisJetzt<0.1) || this.state.paymentinfo.storePaymentInfo) {
              statusneu = 'zahlungNostripe';
            }
            else {
              statusneu = 'zahlungStripe';
            }
          }
          else if (this.state.bookNoCC) {
            this.setBooking();
          }
        }
        else {
          this.setBooking();
        }
      }
    }
    else if (status==='zahlungNostripe') {
      if (this._CCNoStripe.current.checkAllFields()) {
        this.setBooking();
      }
    }
    if (statusneu!=='') {
      this.setState({status:statusneu, error: false}, this.trackPage);
      history.pushState && history.pushState({status:statusneu}, statusneu, '#'+statusneu);
    }
  }
  clickBack = event => {
    var status = this.state.status;
    const ang = this.state.selBooking.angebot.list[0];
    var statusneu = '';
    if (status==='rates') {
      statusneu = this.checkHasServices(ang.services)?'zusatzleistungen':'angebot';
    }
    else if (status==='zusatzleistungen') {
      statusneu = 'angebot';
    }
    else if (status==='personendaten') {
      statusneu = this.state.availRates>1?'rates': (this.checkHasServices(ang.services)?'zusatzleistungen':'angebot');
    }
    else if (status==='zahlungDatatrans') {
      statusneu = 'personendaten';
    }
    else if (status==='zahlungNostripe') {
      statusneu = 'personendaten';
    }
    else if (status==='zahlungStripe') {
      statusneu = 'personendaten';
    }
    this.setState({status: statusneu, error: false}, this.trackPage);
    history.pushState && history.pushState({status:status}, status, '#'+status);
  }
  angebotDifferent = (n) => event => {
    if (n!==1) {
      scrollToElement('suchFilter_wrap', 'suchFilter_wrap', 250, true, true);
      document.getElementById('terminAnkunft').focus();
    }
    else {
      scrollToElement('suchFilter_wrap', 'suchFilter_wrap', 200, true, true);
      this.setState({ filterMultiroomfilter: {}, filterGeschlecht: [], filterTicked: []}, this.getAngebote);
    }
  }
  handlePersonenfeld = (n, v) => {
    this.setState((state) => {
      return { ['pf_'+n]: v}
    });
    
  }
  handleCCFeld = (n, v) => {
    this.setState((state) => {
      return { ['cc_'+n]: v}
    });
    
  }
  overlayOpen = (key) => event => {
    if (pt[key]) {
      this.setState({overlay:textileConvert(pt[key])});
    }
  }
  overlayClose = event => {
    this.setState({overlay:false});
  }
  checkConfirm = (k) => event => {
    this.setState({['confirm'+k]: event.target.checked});
  };
  dispCheckInfo = (k) => event => {
    var t = pt.hostel_zahlunggeleseninfo[k];
    if (t) {
      this.setState({overlay:textileConvert(t)});
    }
  };
  changeBooking = () => {
    var erwachsene = 0;
    var kind = 0;
    var kind2 = 0;
    for (var i = 0; i<this.state.bookingData.angebot.list.length;i++) {
      erwachsene += this.state.bookingData.angebot.list[i].erwachsene;
      kind += this.state.bookingData.angebot.list[i].kind;
      kind2 += this.state.bookingData.angebot.list[i].kind2;
    }
    var bd = this.state.bookingData.angebot.list[0];
    this.setState( 
      {
        filterAnkunft: moment(bd.ankunft), filterNaechte: bd.naechte, filterErwachsene: erwachsene, filterKind: kind, filterKind2: kind2, 
        resnr_changing:this.state.resnr_booked, resnr_booked:0
      }, 
      this.getAngebote
    );
  }
  cancelBooking1 = () => {
    this.setState({status:'cancel-confirm'});
  }
  cancelBooking0 = () => {
    this.setState({status:'change'});
  }
  cancelBooking2 = () => {
    cancelReservation(this.state, this.setAppState);
  }
  print = () => {
    window.print();
  }
  
  selectAngebot = (id) => e => {
    this.selectAngebotId(id);
  }
  selectAngebotId = (id) => {
    sendAnalyticsEvent(this.state, '01_Offer-Selected');

    var thisstate = this.state;
    var bd = thisstate.bookingData;
    var angebot = {};
    for (var i = 0;i < thisstate.dormresults.length; i++) {
      if (thisstate.dormresults[i].angId === id) {
        angebot = thisstate.dormresults[i];
        break;
      }
    }
    const avRates = angebot.list[0].rates.length;
    angebot.list[0].resnr = this.state.resnr_changing;
    angebot.list[0].erwachsene = this.state.filterErwachsene;
    angebot.list[0].kind = this.state.filterKind;
    angebot.list[0].kind2 = this.state.filterKind2;
    angebot.list[0].naechte = this.state.filterNaechte;
    angebot.list[0].coupon = this.state.filterCoupon;
    bd.angebot = angebot;
    const statusneu = this.checkHasServices(bd.angebot.list[0].services)?'zusatzleistungen':(avRates>1?'rates':'personendaten');
    
    var res = { 
      selectedAngebotId: id,
      selBooking: bd,
      bookingData: bd,
      status: statusneu,
    };
    this.setState((state) => {
      const services = bd.angebot.list[0].services;
      for (i=0;i<services.length;i++) {
        var z = services[i];
        z.anzahl = state.zusatzAnzahl[z.zkat]?state.zusatzAnzahl[z.zkat]:0;
        if (z.online_type === constants.zusatzTypAnzahl && z.anzahl > 0 && z.anzahl > z.exp_anz) {
          state.zusatzAnzahl[z.zkat] = z.exp_anz;
        }
        if (z.online_type !== constants.zusatzTypAnzahl && z.anzahl > 0) {
          state.zusatzAnzahl[z.zkat] = z.exp_anz;
        }
        if (z.online_type === constants.zusatzTypFix) {
          state.zusatzAnzahl[z.zkat] = z.exp_anz;
        }
      }
      res.zusatzAnzahl = state.zusatzAnzahl; 
      res.selectedRate = avRates>1?0:angebot.list[0].rates[0].rate_id;
      res.availRates = avRates; 
      return res;
    });
  }
  
  selectRate = (id) => e => {
    sendAnalyticsEvent(this.state, '02b_Rate-Selected');
    this.setState({selectedRate: id, status:'personendaten'}); 
  }
  
  selectLeistung = (katId, anz) => {
    this.setState((state) => {
      state.zusatzAnzahl[katId] = anz;
      return { zusatzAnzahl: state.zusatzAnzahl};
    });    
  }
  
  sendICS = () => {
    const el = this.state.bookingData.angebot.list[0];
    
    var ts = moment.utc().format();
    ts = ts.replace(/-/g, '');
    ts = ts.replace(/:/g, '');
    ts = ts.substr(0,15);
    ts += 'Z';
    
    var text = '';
    el.rooms.map(room=>{
      var pk = room.pkat;
      text += pt.preiskat_kurztext[pk]?pt.preiskat_kurztext[pk]:pt.preiskat_bezeichnung[pk];
      text += getGenderText(el.rooms[0].geschlecht);
      text += ' / ';
    });
    text = text.slice(0, -3);
    
    text = unescape(encodeURIComponent(text));
    var summary = this.state.konfig.hostelname + ' - ' + st['bwh.reservation'] + ': ';
    summary += this.state.konfig['hostel_id'] + '-' + el.resNr;
    const citime = this.state.konfig.checkintime?'T'+this.state.konfig.checkintime.replace(':', '')+'00':'';
    const cotime = this.state.konfig.checkouttime?'T'+this.state.konfig.checkouttime.replace(':', '')+'00':'';
    
    var ics = "BEGIN:VCALENDAR\n";
    ics += "VERSION:2.0\n";
    ics += "BEGIN:VEVENT\n";
    ics += "CLASS:PUBLIC\n";
    ics += "DTSTAMP:" + ts + "\n";
    ics += "CREATED:" + ts + "\n";
    ics += "DESCRIPTION:" + text + "\n";
    ics += "DTSTART;VALUE=DATE:" + moment(el.ankunft).format('YYYYMMDD') + citime + "\n";
    ics += "DTEND;VALUE=DATE:" + moment(el.abreise).format('YYYYMMDD') + cotime + "\n";
    ics += "LOCATION:" + this.state.konfig.location + "\n";
    ics += "SEQUENCE:0\n";
    ics += "SUMMARY:" + summary + "\n";
    ics += "END:VEVENT\n";
    ics += "END:VCALENDAR\n";
    
    const fakeLink = document.createElement('a');
    fakeLink.style.display = 'none';
    document.body.appendChild(fakeLink);
    const blob = new Blob([ics], { type: 'text/calendar', charset: 'utf8' });
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        // Manage IE11+ & Edge
        window.navigator.msSaveOrOpenBlob(blob, 'reservation.ics');
    } else {
        fakeLink.setAttribute('href', URL.createObjectURL(blob));
        fakeLink.setAttribute('download', 'reservation'+this.state.konfig['hostel_id'] + '-' + el.resNr+'.ics');
        fakeLink.click();
    }
  }

  dummy = event => {
  }
  
  render() {
    const {konfig, status, paymentinfo, sprache, bookingData, selectedRate } = this.state;

    if (!st['bwh.footer.bedingung1'] || !konfig.defaultsprache || !paymentinfo.waehrung) {
      return ( 
        <div className="loading">
          <img src={LoadingIcon} style={{display:"block", margin:'auto'}} alt={"Loading"}  />
        </div>
      );
    }

    moment.locale(sprache);
    
    const teststyle=this.state.is_test?{backgroundColor:"rgba(242,242,242,0.8)"}:null;
    
    var dateFormat = (sprache!=='de'?"ddd MMM Do YY":"ddd Do MMM YY");
    if (sprache==='pt') { dateFormat = "ddd, D [de] MMM YY" }
    
    if (this.state.servererror) {
      return ( <h1>Server error. Try again later</h1> );
    }
    
    var i;
    var j;
    var el;
    var total = 0;
    var total_service = 0;
    var rabatt = 0;
    var kommision = 0;
    var paynow = 0;
    var ohnerabatt = 0;
    var offen = 0;
    const total_noservice_rate = {};

    if (bookingData.angebot && bookingData.angebot.list) { 
      const separatFee = bookingData.angebot.list[0].rates[0].preis.commission && this.state.konfig.pricenocomm!==0 && this.state.konfig.pricenocomm!==2;
      for(i=0;i<bookingData.angebot.list.length;i++) {
        el = bookingData.angebot.list[i];
        const selRate = findRate(el.rates, selectedRate);
        el.rates.forEach((rate) => {
          rate.total = 0;
          rate.total_service = 0;
          rate.rabatt = 0;
          rate.kommision = 0;
          rate.paynow = 0;
          rate.ohnerabatt = 0;
          rate.offen = 0;
          var rab = rate.preis.rabatt 
          for(j=0;j<rate.roompreis.length;j++) {
            var pobj = rate.roompreis[j];
            var p = pobj.room;
            var komm = pobj.commission;
            if (!separatFee) {
              p += komm;
              komm = 0;
            }
            p -= rab;
            rab = 0;
            rate.total += p;
            rate.kommision += komm;
            if (rate.rate_id === selRate.rate_id) {
              el.rooms[j].roompreis = p;
            }
          }
          rate.rabatt += rate.preis.rabatt;
          rate.paynow += rate.preis.payNow; //früher: rate.preis.commission;
          
          const services = el.services?el.services:[];
          for (j=0;j<services.length;j++) {
            var s = services[j];
            rate.total_service += (this.state.zusatzAnzahl[s.zkat]?this.state.zusatzAnzahl[s.zkat]:0) * s.preis;
          }
          rate.total += rate.total_service;
          rate.total += rate.kommision;
          rate.ohnerabatt = rate.total;
          rate.total += rate.rabatt;
          rate.offen = rate.total - rate.paynow;

          if (rate.rate_id === selRate.rate_id) {
            total += rate.total;
            total_service += rate.total_service;
            rabatt += rate.rabatt;
            kommision += rate.kommision;
            paynow += rate.paynow;
            ohnerabatt += rate.ohnerabatt;
            offen += rate.offen;
          }
          if (!total_noservice_rate[rate.rate_id]) total_noservice_rate[rate.rate_id] = 0;
          total_noservice_rate[rate.rate_id] += rate.total - rate.total_service;
        })
      }
  
      // Payment Points
      if (this.state.hasRates) {
        [0,1].forEach((typ)=>{
          var ankunft = 99999999;
          var datumarr = {};
          var serv_ankunft = 0;
          var serv_nacht = 0;
          for(var i=0;i<this.state.bookingData.angebot.list.length;i++) {
            const el = this.state.bookingData.angebot.list[i];
            ankunft = Math.min(ankunft, el.ankunft);
            for (var j=0; j<el.dauer; j++) {
              datumarr[moment(el.ankunft).add(j, 'days').format('YYYYMMDD')] = true;
            }
            const services = el.services?el.services:[];
            for (var j=0;j<services.length;j++) {
              var s = services[j];
              if (typ===1 && s.notonbwh === 0) continue;
              
              var amount = (this.state.zusatzAnzahl[s.zkat]?this.state.zusatzAnzahl[s.zkat]:0) * s.preis;
              if(s.def_type===constants.zusatzAnzN || s.def_type===constants.zusatzAnzNP ) {
                serv_nacht += amount;
              }
              else {
                serv_ankunft += amount;
              }
            }
          }
          const nights = Object.keys(datumarr).length;
  
          for(var i=0;i<this.state.bookingData.angebot.list.length;i++) {
            const ang = this.state.bookingData.angebot.list[i];
            ang.rates.forEach((rate)=> {
              const total_noservice = total_noservice_rate[rate.rate_id];
              const tx_booking = typ?'bwh.rate.abbooking':'bwh.rate.atbooking';
              const tx_arrival = typ?'bwh.rate.noshow':'bwh.rate.atcheckin';
  
              const list = rate.allpoints[typ];
              var last = 0;
              {Object.keys(list).map((k)=>{
                const row = list[k];
                const d = moment(k);
            
                var now = false;
                var date = d.format(dateFormat);
                row.dateTxt = row.date;
                if (d.format('YYYYMMDD')===moment().format('YYYYMMDD')) {
                  row.dateTxt = tx_booking;
                  now = true;
                } 
                else if (parseInt(d.format('YYYYMMDD'))===ankunft) {
                  row.dateTxt = tx_arrival;
                } 
                
                var amount = 0;
                var betrag = parseFloat(row.betrag);
                if (row.betragart === 'fix') {
                  amount = betrag;
                }
                else if (row.betragart === 'prozent') {
                  amount = total_noservice * betrag / 100;
                  amount += serv_ankunft * betrag / 100;
                  amount += serv_nacht * betrag / 100;
                }
                else if (row.betragart === 'naechte') {
                  const f = Math.min(1, betrag / nights);
                  amount = total_noservice * f;
                  amount += serv_ankunft;
                  amount += serv_nacht * f;
                }
                else {
                  amount = total_noservice;    
                  amount += serv_ankunft;    
                  amount += serv_nacht;    
                }
                
                if (now && rate.paynow && typ===0) amount = rate.paynow;
                if (now && rate.paynow && typ===1) amount = Math.max(amount, rate.paynow);
                
                if (typ===0 && ang.paymentPoints) {
                  ang.paymentPoints[rate.rate_id].forEach((point) => {
                    if (! Array.isArray(point)) {
                      if (point.date === k) {
                        point.preis = amount;
                      }
                    }
                  })
                } 
                const lastcheckin = last;
                if (typ===0) last = amount;
                    
                row.preisMod = amount - lastcheckin; 
                
              })}
            })
          }
        })
      }
    }

    const preisDisp = total.toFixed(paymentinfo.decimal)+' '+paymentinfo.waehrung;
    const anzAngebote = !this.state.dormresults?0:this.state.dormresults.length;
    
    var specialdays = false;
    if (this.state.specialday && typeof this.state.specialday === 'object') {
      specialdays = [];
      for (var datum in this.state.specialday) {
        var spec = this.state.specialday[datum];
        if (spec[this.state.sprachId]) {
          specialdays.push(<tr><td style={{textAlign:"right"}}><p>{moment(datum).format(dateFormat)}</p></td><td style={{paddingLeft:"15px"}}><p>{spec[this.state.sprachId]}</p></td></tr>  );
        }
      }
    }
    
    const stripeLocale = this.state.sprache; //'en'; //en: Damit fehlermeldung im Log immer English 
    
    const filterParts = this.state.filterHash.split('_');
    const suchHeaderText = st['bwh.suchresultate.headertext']
            .replace('{results}', this.state.dormresults.length)
            .replace('{arrival}', moment(filterParts[0]).format(dateFormat))
            .replace('{departure}', moment(filterParts[0]).add(filterParts[1], 'days').format(dateFormat))
            .replace('{persons}', parseInt(filterParts[2])+parseInt(filterParts[3])+parseInt(filterParts[4]));
    
    return (
      <div style={this.state.is_test?{background:'url('+TextBgBild+')'}:null}>
  {konfig.agentcss && 
        <link rel="stylesheet" type="text/css" href={konfig.agentcss} />
  }
      <div id="primaryContainer" className="primaryContainer clearfix">
        <Kopf appState={this.state} setAppState={this.setAppState} loadSprache={this.loadSprache} doVerifyLogin={this.doVerifyLogin} />
      {this.state.resnr_changing?
        <h2 style={{textAlign:'center',margin: '4px'}}>{st['bwh.aktionen.resnrchanging']} {this.state.resnr_changing}</h2>
      :null}
        <SuchFilter appState={this.state} setAppState={this.setAppState} teststyle={teststyle} dateFormat={dateFormat} moment={moment} 
          getAngebote={this.getAngebote} specialdays={specialdays}
          handleRoomFilter={this.handleRoomFilter} handleGeschlechtFilter={this.handleGeschlechtFilter} changeCode={this.changeCode}
        
        />
  {status === 'change' && !this.state.checkin &&
        <div id="buchungfertig_wrap" className="clearfix">
          <div id="buchungfertig" className="clearfix">
            <div className="clearfix">
              <p id="buchungfertig_text" dangerouslySetInnerHTML={{__html: textileConvert(this.state.justBooked?this.state.bookConfirmText:pt.hostel_showbookingtext)}}></p>
            </div>
            <div id="confirmAktionen_wrap">
    {!bookingData.pastcanceltime &&
            <React.Fragment>
                <div id="confirmAktionChangeBookingButton_wrap" className="confirm-aktion-button-wrap">
        {(!bookingData.rate_id && bookingData.changable)?
                  <div className="confirm-aktion-button" onClick={this.changeBooking}>
                    <p id="confirmAktionChangeButton" className="confirm-aktion-button-text">{st['bwh.aktionen.change']}</p>
                  </div>
        :
                  <a className="confirm-aktion-button" href={'mailto:'+this.state.konfig['affiliate.email']+'?subject='+(encodeURI(st['bwh.changeanemailsubj']).replace('Resnr', bookingData.angebot.list[0].resNr))} >
                    <p id="confirmAktionChangeButton" className="confirm-aktion-button-text">{st['bwh.aktionen.change']}</p>
                  </a>
        }
                </div>
                <div id="confirmAktionCancelReservationButton_wrap" className="confirm-aktion-button-wrap">
                  <div className="confirm-aktion-button" onClick={this.cancelBooking1}>
                    <p id="confirmAktionCancelReservationButton" className="confirm-aktion-button-text">{st['bwh.aktionen.cancelreservation']}</p>
                  </div>
                </div>
              </React.Fragment>
    }
              <div id="confirmAktionKalendereintragButton_wrap" className="confirm-aktion-button-wrap">
                <div className="confirm-aktion-button" onClick={this.sendICS}>
                  <p id="confirmAktionKalendereintragButton" className="confirm-aktion-button-text">{st['bwh.aktionen.calEntry']}</p>
                </div>
              </div>
              <div className="confirm-aktion-button-wrap">
                <div className="confirm-aktion-button" onClick={this.print}>
                  <p id="confirmAktionDruckenButton" className="confirm-aktion-button-text">{st['bwh.aktionen.print']}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
  }
  {status === 'change' && !!this.state.checkin &&
        <div id="buchungfertig_wrap" className="clearfix">
          <OnlineCheckin appState={this.state} setState={this.setAppState} />
        </div>
  }
  {status === 'cancel-confirm' &&
        <div id="wirklichstornieren_wrap" className="clearfix">
          <div id="wirklichstornieren" className="clearfix">
            <div className="clearfix">
              <p id="wirklichstornieren_text">{st['bwh.aktionen.storno.text'].replace('{RESNR}', this.state.bookingData.angebot.list[0].resNr)}</p>
            </div>
            <div id="wirklichstornierenAktionen_wrap">
              <div id="wirklichstornierenAktionenAbbrechenButton_wrap" className="confirm-aktion-button-wrap halfwidth">
                <div className="confirm-aktion-button" onClick={this.cancelBooking0}>
                  <p id="wirklichstornierenAktionenAbbrechenButton" className="confirm-aktion-button-text">{st['bwh.aktionen.storno.abbrechen']}</p>
                </div>
              </div>
              <div id="wirklichstornierenAktionenStornierenButton_wrap" className="confirm-aktion-button-wrap halfwidth">
                <div className="confirm-aktion-button" onClick={this.cancelBooking2}>
                  <p id="wirklichstornierenAktionenStornierenButton" className="confirm-aktion-button-text">{st['bwh.aktionen.storno.ok']}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
  }
  {(status!=='angebot' && status!=='-' && status!=='canceled') &&
        <DisplayAngebot appState={this.state} teststyle={teststyle} moment={moment} dateFormat={dateFormat} rabatt={rabatt} 
          konfig={konfig} total={total} total_service={total_service} paynow={paynow} offen={offen} ohnerabatt={ohnerabatt} specialdays={specialdays} />
  }      
        <ConfirmInfo appState={this.state} teststyle={teststyle}  />
        <BackNext top={true} appState={this.state} clickBack={this.clickBack} clickNext={this.clickNext} preis={preisDisp} />
  {status === '-' && 
        <div className="clearfix viewheader"><p className="viewheadertext"></p></div>
  }
  {!!this.state.error &&
        <div>
          <div className="clearfix formularansicht">
            <div id="zahlungsdatenFormularErrorMessage" className="formular-reqmsg clearfix" style={{display:'block'}}>
              <p id="zahlungsdatenFormularErrorMessageText" className="formular-reqmsg-text">{st[this.state.error]?st[this.state.error]:this.state.error}</p>
            </div>
          </div>
        </div>
}
  {status === 'angebot' &&
        <div id="suchresultate_wrap" className="clearfix datenansicht-wrap">
    {this.state.dormresults===false?null
     :(this.state.dormresults.length?
          <p id="suchresultate_headertext" className={"viewheadertext filterHash_"+this.state.filterHash}>
            {suchHeaderText}
       {pt.hostel_resultfound && 
            <span style={{lineHeight:'1.3em'}} dangerouslySetInnerHTML={{__html: textileConvert(pt.hostel_resultfound)}} />
       }
          </p>
    :
          <p id="suchresultate_headertext2" className={"viewheadertext filterHash_"+this.state.filterHash} dangerouslySetInnerHTML={{__html: textileConvert(pt.hostel_noresultfound)}} />
    )}
    {this.state.minstay > 1 && anzAngebote===0 &&
          <p id="suchresultate_headertext4" className="viewheadertext">{st['bwh.suchresultate.headertext4'].replace('{minstay}', this.state.minstay)}</p>
    }
    {this.state.minstay > 1 && anzAngebote>0 &&
          <p id="suchresultate_headertext4" className="viewheadertext" style={{paddingTop:0}}>{st['bwh.suchresultate.headertext5'].replace('{minstay}', this.state.minstay)}</p>
    }
          <div id="suchresultate" className="clearfix datenansicht">
            <Angebote 
              res={this.state.dormresults} 
              pricenocomm={konfig.pricenocomm}
              decimal={paymentinfo.decimal}
              waehrung={paymentinfo.waehrung}
              selectedAngebotId={this.state.selectedAngebotId} 
              selectAngebot={this.selectAngebot}
              angebotDifferent={this.angebotDifferent}
              konfig={konfig}
              selectedRate={selectedRate}
            />
          </div>
        </div>
  }
  {status === 'rates' &&
        <div id="zusatzleistungen_wrap" className="clearfix datenansicht-wrap">
          <div className="clearfix viewheader">
            <p id="zusatzleistungen_headertext" className="viewheadertext">{st['bwh.rate.rates']}: {st['bwh.rates.headertext']}</p>
          </div>
          <div id="rates_liste" className="clearfix datenansicht">
            <Rates  
              appState = {this.state}
              moment = {moment}
              dateFormat={dateFormat}
              selectRate = {this.selectRate}
              paymentinfo={paymentinfo}
            />
          </div>
        </div>
  }
  {status === 'zusatzleistungen' &&
        <div id="zusatzleistungen_wrap" className="clearfix datenansicht-wrap">
          <div className="clearfix viewheader">
            <p id="zusatzleistungen_headertext" className="viewheadertext">{st['bwh.zusatzleistungen.headertext']}</p>
          </div>
          <div id="zusatzleistungen_liste" className="clearfix datenansicht">
            <Leistungen 
              appState = {this.state}
              decimal={paymentinfo.decimal}
              waehrung={paymentinfo.waehrung}
              selectedLeistungen={this.state.selectedLeistungen} 
              selectLeistung={this.selectLeistung}
              showtotservice={konfig.showtotservice}
            />
          </div>
        </div>
  }
  {status === 'personendaten' &&
        <Personendaten ref={this._personendaten} konfig={konfig} change={this.handlePersonenfeld} appState={this.state} paymentinfo={this.state.paymentinfo} />
  }
  {status === 'zahlungDatatrans'?
        <DatatransForm preis={preisDisp} appState={this.state} setAppState={this.setAppState} konfig={konfig} 
          setBooking={this.setBooking} change={this.handleCCFeld} 
          checkConfirm={this.checkConfirm} dispCheckInfo={this.dispCheckInfo} />
  :null}
  {status === 'zahlungNostripe'?
        <StripeProvider apiKey={paymentinfo.stripe_pk}>
          <Elements>
            <CCDatenNoStripe appState={this.state} setAppState={this.setAppState} checkConfirm={this.checkConfirm} 
              dispCheckInfo={this.dispCheckInfo} setBooking={this.setBooking}
              ref={this._CCNoStripe} konfig={konfig} change={this.handleCCFeld} paymentinfo={this.state.paymentinfo} 
              preis={preisDisp} />
          </Elements>
        </StripeProvider>
  :null}
  {status === 'zahlungStripe'?
        <StripeProvider apiKey={paymentinfo.stripe_pk} locale={stripeLocale} >
          <Elements>
            <StripeForm appState={this.state} setAppState={this.setAppState} checkConfirm={this.checkConfirm} dispCheckInfo={this.dispCheckInfo}
              preis={preisDisp} setBooking={this.setBooking} logtext={this.logtext} />
          </Elements>
        </StripeProvider>
  :null}
  
  {status === 'setBooking' &&
        <img src={LoadingIcon} style={{display:"block", margin:'auto'}} alt={"Loading"} />
  }
  {status === 'canceled' &&
        <div className="clearfix canceled">
          <div className="clearfix">
            <div className="clearfix">
              <h1 style={{margin:'2rem auto', maxWidth:'40rem', textAlign:'center'}} dangerouslySetInnerHTML={{__html: this.state.cancelTitel}}></h1>
              <h3 style={{margin:'0 auto', maxWidth:'40rem', textAlign:'center'}} dangerouslySetInnerHTML={{__html: this.state.cancelText}}></h3>
            </div>
          </div>
        </div>
  }
        <BackNext top={false} appState={this.state} clickBack={this.clickBack} clickNext={this.clickNext} preis={preisDisp} />

        <Fuss appState={this.state} overlayOpen={this.overlayOpen} bwh_version={bwh_version} />
      </div>
      
  {this.state.overlay &&
      <div id="overlay-impress" className="overlayImpressBG" onClick={this.overlayClose}>
        <div id="overlay-impress-wrapper">
          <div id="overlay-impress-content" className="msg">
            <div id="overlay-impress-content-text" dangerouslySetInnerHTML={{__html: this.state.overlay}} />
          </div>
        </div>
        <div id="overlay-impress-navbar">
          <i id="overlay-impress-close" className="flaticon-circle" title="Close" onClick={this.overlayClose}></i>
        </div>
      </div>      
  }      
    </div>
  //    <script src="../bwh_js/libs/moment-with-locales.min.js"></script>
  //    <script src="../bwh_js/libs/pikaday.js"></script>
  //    <script src="../bwh_js/libs/textile.min.js"></script>  
    );
  }
}

export default App;
