import React, { useState } from 'react';
import { st, pt } from './texte'
import { constants } from './common';

const DispTyp = (props) => {
  if (props.showtotservice) {
    return null;
  }
  return (
    <p className="preisbeschrieb">
  {(props.data.def_type===constants.zusatzAnzP || props.data.def_type===constants.zusatzAnzNP) &&
      <span style={{display:'block'}}>{st['bwh.zusatz.properson']}</span>
  }
  {(props.data.def_type===constants.zusatzAnzN || props.data.def_type===constants.zusatzAnzNP) &&
      <span>{st['bwh.zusatz.pronacht']}</span>
  }
    </p>
  );
}

const PreisAnzeige = (props) => {
  if (Math.round(props.preis * 100) != 0) return (
    <div className="clearfix roompreis_wrap">
      <p className="roompreis">{props.preis.toFixed(props.decimal)}</p>
      <p className="roomwaehrung">{props.waehrung}</p>
      <DispTyp showtotservice={props.showtotservice} data={props.data} />
    </div>
  )
  return (
    <div className="clearfix roompreis_wrap">
      <p className="roompreis" style={{fontSize:'1.5em'}}>{st['bwh.zusatz.preis.free']}</p>
    </div>
  )
}

export const Leistung = (props) => {
 
  const [mouseOver, setMouseOver] = useState(false);
  const [inEmpty, setInEmpty] = useState(props.data.anzahl===0);
  
  const SetChildren = (s, anz) => {
    if (s.anz_mod_list && s.anz_mod_list.ChildService) {
      s.anz_mod_list.ChildService.map(sid=>{
        if (anz===0) {
          props.selectLeistung(sid, 0);    
        }
        else {
          var setAnz = 0;
          const z = props.leistObj[sid];
          if (z) {
            setAnz = z.exp_anz;
          }
          props.selectLeistung(sid, setAnz);    
        }
      }) 
    }
  }

  const UnsetService = (s) => {
    if (s.anz_mod_list && s.anz_mod_list.UnsetService) {
      s.anz_mod_list.UnsetService.map(sid=>{
        props.selectLeistung(sid, 0);    
        SetChildren(props.leistObj[sid], 0);
      }) 
    }
  }
  
  const handleClick = event => {
    var s = props.data;
    var anz;
    if (s.anzahl>0) {
      anz = 0;
    }
    else {
      anz = s.exp_anz;
      UnsetService(s);
    }
    props.selectLeistung(s.zkat, anz);    
    SetChildren(s, anz);
  }
  
  const handleAnz = event => {
    const anz = parseInt(event.target.value);
    props.selectLeistung(props.data.zkat, anz);
    setMouseOver(false);
    setInEmpty(props.data.anzahl===0);
    if (anz > 0) {
      const z = props.leistObj[props.data.zkat];
      if (z) {
        UnsetService(z)
      }
    }
  }
  
  const mouseIn = event => {
    setMouseOver(true);
    setInEmpty(props.data.anzahl===0);
  }
  const mouseOut = event => {
    setMouseOver(false);
    setInEmpty(props.data.anzahl===0);
  }
  
  const CalcPrice = (z) => {
    var p = parseFloat(z.preis);
    if (z.def_type === constants.zusatzNetto || z.def_type === constants.zusatzAvgN) {
      p *= z.exp_anz;
      if (z.online_type === constants.zusatzTypAnzahl) {
        z.online_type = constants.zusatzTypJaNein;
      }
    }
    if (z.anzahl) {
      p *= z.anzahl;
    }
    else {
      p *= z.exp_anz;
    }
    return p;
  }
  
  const {data, decimal, waehrung, showtotservice} = props;
  var totAnz = data.anzahl?data.anzahl:data.exp_anz;
  var p = CalcPrice(data);
  if (data.anz_mod_list && data.anz_mod_list.ChildService) {
    data.anz_mod_list.ChildService.map(sid=>{
      const z = props.leistObj[sid];
      if (z) {
        p += CalcPrice(z); 
        totAnz += (z.anzahl?z.anzahl:z.exp_anz);
      }
    }) 
  }
  
  if (!props.showtotservice && totAnz) {
    p /= totAnz;
  }
  

  const anzListe = [];
  for(var i=0; i<=props.data.exp_anz; i++) {
    anzListe.push(<option value={i} key={i}>{i}</option>);
  }
  
  var checktext = '';
  if (mouseOver) {
    if (data.anzahl>0) {
      checktext = inEmpty?st['bwh.zusatz.ausgewaehlt']:st['bwh.zusatz.abwaehlen'];
    }
    else {
      checktext = st['bwh.zusatz.auswaehlen'];  
    }
  } 
  else {
    if (data.anzahl>0) {
      checktext = st['bwh.zusatz.ausgewaehlt'];
    }
  }
  
  return (
    <div className="clearfix angebot_wrap">
      <div className="clearfix angebot">
  {data.online_type !== constants.zusatzTypFix?        
        <div className="room vis" style={data.bildsrc?{backgroundImage:"url("+data.bildsrc+")"}:null} onClick={handleClick}
          onMouseOut={mouseOut} onMouseOver={mouseIn}>
          <div className={"clearfix choice"+((data.anzahl>0 && !inEmpty)?" choice_selected":"")}>
            <div className="image gutzeichenzusatz"></div>
            <p className="my_choice">{checktext}</p>
          </div>
          <div className="clearfix roomverlauf"></div>
          <PreisAnzeige preis={p} decimal={decimal} waehrung={waehrung} showtotservice={showtotservice} data={data} />
        </div>
  :
        <div className="room vis roomzusatzfix" style={data.bildsrc?{backgroundImage:"url("+data.bildsrc+")"}:null}>
          <div className="clearfix choice choice_selected_fix">
            <div className="image gutzeichenzusatz"></div><p className="my_choice">{st['bwh.zusatz.ausgewaehlt']}</p>
          </div>
          <div className="clearfix roomverlauf"></div>
          <PreisAnzeige preis={p} decimal={decimal} waehrung={waehrung} showtotservice={showtotservice} data={data} />
        </div>
  }            
        <div className="clearfix angebotbeschreibung">
          <div className="angebotbeschreibung-zeile1">
            <p className="angebotbeschreibung-zeile1-text">{pt.zusatz_bezeichnung[data.zkat]}</p>
  {data.online_type === constants.zusatzTypAnzahl &&                      
            <select value={data.anzahl} className="zusatzauswahlanzahl" onChange={handleAnz}>
              {anzListe}
            </select>              
  }
          </div>
          <p className="angebotbeschreibung-zeile2">{pt.zusatz_kurztext[data.zkat]}</p>
        </div>
      </div>
    </div>
  );
}
export default Leistung;