import React from 'react';
import { st, pt, lt } from './texte'
import { InputFeld, SelectFeld } from './Felder'
import { constants, validateEmail } from './common';

const isFieldRequired = (fieldConf, paymentinfo) => {
    var bRet = false;
    if (fieldConf === constants.PersDataObligatorisch) {
      bRet = true;
    }
    else if (fieldConf === constants.PersDataObligOhneKK 
       && paymentinfo.BookingFee === constants.bfNoPaymentInfo)
    {
      bRet = true;
    }
    return bRet;
  }

export class Personendaten extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {};
    var gfConfig = props.konfig.guestFieldConfigData;
    for (var elId in gfConfig) {
      var conf = parseInt(gfConfig[elId]);
      this.state['req_'+elId] = isFieldRequired(conf, this.props.paymentinfo);
      this.state['dispv_'+elId] = 0;
      this.state['valid_'+elId] = !isFieldRequired(conf, this.props.paymentinfo);
    }
    if (parseInt(props.konfig.usearrivaltime)>1) {
      this.state['req_zArrivalTime'] = true;
      this.state['dispv_zArrivalTime'] = 0;
      this.state['valid_zArrivalTime'] = false;
    }
  }  
  
  fieldChange = (elId) => event => {
    var v = event.target.value;
    var valid = true;
    if (this.props.konfig.guestFieldValidationData[elId]) {
      const validate = this.props.konfig.guestFieldValidationData[elId];
      if (validate==='email') {
        valid = valid && validateEmail(v);
      }
      else {
        const pattern = new RegExp(validate);
        valid = valid && pattern.test(v);
      }
    }
    
    if (this.state['req_'+elId]) {
      valid = valid && v.length>0 && v!=="0";
      if (elId===constants.PersFEmail) {
        valid = valid && validateEmail(v);
      }
      if (elId===constants.PersFEmail2) {
        valid = valid && (v === this.props.appState['pf_'+constants.PersFEmail]);
      }
    }
    this.setState({['dispv_'+elId]: valid?1:-1, ['valid_'+elId]:valid })
    this.props.change(elId, v);
  }
  
  checkAllFields = () => {
    var ok = true;
    for(var k in this.state) {
      var set = {};
      if (k.substr(0,4)==='req_' && this.state[k]) {
        var elId = k.substr(4);
        var v = this.props.appState['pf_'+elId];
        if (!v) {
          v = '';
        }
        var invalid = false;
        invalid = v.length===0 || v==="0";
        if (!invalid) {
          if (parseInt(elId)===constants.PersFEmail) {
            invalid = !validateEmail(v);
          }
          if (parseInt(elId)===constants.PersFEmail2) {
            invalid = v !== this.props.appState['pf_'+constants.PersFEmail];
          }
        }
        if (invalid) {
          set['dispv_'+elId] = -1;
          set['valid_'+elId] = false;
          ok = false;
        }
      }
      this.setState(set);
    }
    return ok;
  }
  
  render (){
    const { konfig, appState, change, ...other } = this.props;
    
    var laenderlist = [];
    for (var i=0;i<lt.list.length;i++) {
      laenderlist.push({value:lt.list[i]['land_id'], text:lt.list[i]['land']});
    }
    var arrivaltimelist = [];
    for (i in pt.hostel_arrivaltimeoptions) {
      arrivaltimelist.push({value:i, text:pt.hostel_arrivaltimeoptions[i]});
    } 
    
    var felder = [];
    var gfConfig = konfig.guestFieldConfigData;
    for (var elId in gfConfig) {
      elId = parseInt(elId);
      if (elId===constants.PersFLand || elId===constants.PersFPassLand) {
        felder.push(
          <SelectFeld 
            key={elId} 
            name={elId} 
            titel={ pt.hostel_guestfield[elId]} 
            value={appState['pf_'+elId]} 
            required={this.state['req_'+elId]}
            valid={this.state['dispv_'+elId]}
            change={this.fieldChange}
            list={laenderlist}
            {...other} 
          />);
        
      }
      else if (pt.hostel_guestfield_options && pt.hostel_guestfield_options[elId] && pt.hostel_guestfield_options[elId]!== "") {
        
        var options = pt.hostel_guestfield_options[elId].split("\n");
        var optionlist = [];
        for (var i=0;i<options.length;i++) {
          var s = options[i].split('=');
          optionlist.push({value:s[0], text:s[1]?s[1]:s[0]});
        }
        felder.push(
          <SelectFeld 
            key={elId} 
            name={elId} 
            titel={ pt.hostel_guestfield[elId]} 
            value={appState['pf_'+elId]} 
            required={this.state['req_'+elId]}
            valid={this.state['dispv_'+elId]}
            change={this.fieldChange}
            list={optionlist}
            {...other} 
          />);
        
      }
      else {
        felder.push(
          <InputFeld 
            key={elId} 
            name={elId} 
            titel={ pt.hostel_guestfield[elId]} 
            value={appState['pf_'+elId]} 
            required={this.state['req_'+elId]}
            valid={this.state['dispv_'+elId]}
            change={this.fieldChange}
            {...other} 
          />);
        
      }
    }
    
    if (parseInt(konfig.usearrivaltime)>0) {
      felder.push(
        <SelectFeld 
          key="zArrivalTime" 
          name="zArrivalTime" 
          titel={st['bwh.personendaten.formularzusatz.arrivaltime']} 
          value={appState['pf_zArrivalTime']} 
          required={parseInt(konfig.usearrivaltime)>1}
          valid={this.state.dispv_zArrivalTime}
          change={this.fieldChange}
          list={arrivaltimelist}
          {...other} 
        />);
    }

    return (
      <React.Fragment>
        <div id="personendatenFormular_wrap">
          <div className="clearfix viewheader">
            <p id="personendatenFormular_headertext" className="viewheadertext">{st['bwh.personendaten.formular.headertext']}</p>
          </div>
          <div id="personendatenFormular_formular" className="clearfix formularansicht">
            <div className="formularlegende clearfix">
              <p id="personendatenFormularLegende" className="formularlegende-text">{st['bwh.formularpersonendaten.legende']}</p>
            </div>
            <div id="personendatenFormularRequiredMessage" className="formular-reqmsg clearfix">
              <p id="personendatenFormularRequiredMessageText" className="formular-reqmsg-text"></p>
            </div>
            {felder}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default Personendaten;

