import React from 'react';
import { ReactComponent as GutzeichenIcon } from './img/gutzeichen.svg'

export class FilterButton extends React.Component {
 
  render () {
    const icons=this.props.icons?this.props.icons:[];
    return (
      <div className={"toggle-btn"+(this.props.ticked?" gewaehlt":"")} onClick={this.props.update}>
        {icons}
        <p className="toggle-btn-text">{this.props.text}</p>
        {this.props.ticked?
          <GutzeichenIcon className="toggle-btn-gutzeichen image" alt="checked" />
        :null}
      </div>
    );
  }
}
export default FilterButton;