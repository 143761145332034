import React, { Fragment } from 'react';
import { st, pt, lt } from './texte'
import { constants } from './common';
import { InputFeld, SelectFeld, DatumFeld } from './Felder'
import { onlineCheckin } from './Interface.js'

const OnlineCheckin = (props) => { 
  const { appState, setState } = props;
  var checkindata = appState.bookingData.checkin;

  const fieldChange = (n) => event => {
    var v = event.target.value;
    const s = n.split('|');
    setState(st=>{
      var old = st.bookingData.checkin[s[1]][s[0]].value; 
      if (s[2]==='Y') {
        v = (old % 10000) + parseInt(v) * 10000;
      }
      if (s[2]==='M') {
        v = (old - Math.floor(old/100)%100 * 100) + parseInt(v) * 100;
      }
      if (s[2]==='D') {
        v = (old - old % 100) + parseInt(v) ;
      }
      st.bookingData.checkin[s[1]][s[0]].value = v ; 
      return st
    });
  }
  
  const store = () => {
    onlineCheckin(appState, setState, checkindata);
  }

  const laenderlist = [];
  for (var i=0;i<lt.list.length;i++) {
    laenderlist.push({value:lt.list[i]['land_id'], text:lt.list[i]['land']});
  }
  
  const personen = [];
  i = 0;
  for(var id_bett in checkindata) {
    var pers = checkindata[id_bett];
    var felder = [];
    for (var j=0; j<pers.length; j++) {
      var cd = pers[j];
      if (i===0 || cd.typ===2 || cd.typ===4) {
        if (cd.feld===constants.PersFLand || cd.feld===constants.PersFPassLand) {
          felder.push(
            <SelectFeld 
              key={cd.feld} 
              name={j+'|'+id_bett} 
              titel={ pt.hostel_guestfield[cd.feld]} 
              value={cd.value} 
              required={cd.typ > 2}
              change={fieldChange}
              list={laenderlist}
            />);
          
        }
        else if (cd.feld===constants.PersGebDat) {
          felder.push(
            <DatumFeld 
              key={cd.feld} 
              name={j+'|'+id_bett} 
              titel={ pt.hostel_guestfield[cd.feld]} 
              value={cd.value} 
              required={cd.typ > 2}
              change={fieldChange}
            />);
        }
        else {
          felder.push(
            <InputFeld 
              key={cd.feld} 
              name={j+'|'+id_bett} 
              titel={ pt.hostel_guestfield[cd.feld]} 
              value={cd.value} 
              required={cd.typ > 2}
              change={fieldChange}
            />);
          
        }
      }
    }
    
    if (felder.length) {
      personen.push(
        <div id="gewaehltesangebot_wrap" className="filterbereich-wrap clearfix" style={{backgroundColor: 'rgba(242, 242, 242, 0.8)'}} key={i}>
          <div className="filterbereich clearfix">
            <div className="filterbereich-titel">
              <p className="filterbereich-titeltext">{i===0?'Main guest':(i+1)+'. Person'}</p>
            </div>
            <div className="clearfix bereichinhalt-wrap">
              {felder}
            </div>
          </div>
        </div>
      );
    }
    i++;
  }
  return (
    <Fragment>
      <div id="buchungfertig" className="clearfix">
        <div className="clearfix">
          <h1>{st['bwh.checkintitel']}</h1>
        {appState.checkin>1 && <h2>{st['bwh.checkinsaved']}</h2>}
        </div>
      </div>
      {personen}
      <div className="confirm-aktion-button-wrap" style={{float: 'none', margin: '1em auto 8em auto'}}>
        <div id="bu_storechecken" className="confirm-aktion-button" onClick={store}>
          <p className="confirm-aktion-button-text" style={{float: 'none'}}>Store Check-in information</p>
        </div>
      </div>
    </Fragment>
  )
}
export default OnlineCheckin;
