import React from 'react';
import { st, pt } from './texte'
import { ReactComponent as PfeilLinksIcon } from './img/pfeil_links.svg'
import { ReactComponent as PfeilRechtsFettIcon } from './img/pfeil_rechts_fett.svg'

export class BackNext extends React.Component {
  render (){
    const { top, appState, clickBack, clickNext, preis } = this.props;
    var hasBack = appState.status!=='angebot';
    var hasNext = ((appState.status!=='angebot' || appState.selectedAngebotId!=='') 
      && (appState.status!=='rates' || appState.selectedRate!==0) 
      && appState.status!=='zahlungNostripe' && appState.status!=='zahlungDatatrans' && appState.status!=='zahlungStripe');
    const hasBook = appState.status==='zahlungNostripe' 
      || appState.status==='zahlungDatatrans' 
      || (appState.status === 'personendaten' && appState.bookNoCC);
    if (hasBook) {
      hasNext = false;
    }     
    
		//console.log([appState.status, hasBack, top, hasBook, hasNext]);
    if ((hasBack || hasNext) && (
      appState.status === 'angebot' ||
      appState.status === 'rates' ||
      appState.status === 'zusatzleistungen' ||
      appState.status === 'personendaten' ||
      (appState.status === 'zahlungNostripe' && top) ||
      (appState.status === 'zahlungDatatrans' && top) ||
      (appState.status === 'zahlungStripe' && top)
    )){

      if (!top && appState.status === 'personendaten' && hasBook) {
        return (
          <div id="boo_now_leiste_unten" className="book-now-leiste-wrap clearfix">
            <div className="book-now-leiste clearfix handcursor" onClick={clickNext}>
              <div className="book-now-wrap clearfix book-now-wrap-hover">
                <p id="book_now_preis">{st['bwh.preis.total']} {preis}</p>
                <div className="book-now clearfix">
                  <p id="book_now_text_unten" className="book-now-text">{pt.hostel_booknowfinish}</p>
                  <PfeilRechtsFettIcon />
                </div>
              </div>
            </div>
          </div>
        )
      }
      else {
        return (
          <div className="book-now-leiste-wrap-oben nav-leiste clearfix">
      {(hasBack && (top || !hasBook)) &&
            <div className="book-now-leiste-oben clearfix handcursor back" onClick={clickBack} 
              style={!hasNext?{width:'100%'}:null}>
              <div className="book-now-wrap book-now-wrap-oben-links clearfix book-now-wrap-hover">
                <div className="book-now book-now-left clearfix">
                  <p className="book-back-text">{hasBack?st['bwh.cssclass.bookbacktext']:''}</p>
                  {hasBack && <PfeilLinksIcon />}
                </div>
              </div>
            </div>
      }
      {(hasNext) &&
            <div className="book-now-leiste-oben clearfix handcursor next" onClick={clickNext}
              style={!hasBack?{width:'100%'}:null}>
              <div className="book-now-wrap book-now-wrap-oben-rechts clearfix book-now-wrap-hover">
                <div className="book-now clearfix">
                  <p className="book-now-text">{pt.hostel_booknowweiter}</p>
                  {hasNext && <PfeilRechtsFettIcon />}
                </div>
              </div>
            </div>
      } 
          </div> 
        );
      }
    }
    else {
      return null;
    }
  }
}
export default BackNext;