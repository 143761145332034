import React from 'react';
import { st, pt, splitText } from './texte'
import { textileConvert } from './common';

class Block extends React.Component {
  render() {
    const {text, middle, first} = this.props;
    var t = splitText(text?text:'');
    return (
      <div className={"clearfix footerbereich"+(first?" footerbereichfirst":"")}>
        {t.titel.length>0 && <p className="footerbereich-titel" dangerouslySetInnerHTML={{__html: t.titel}}></p>}
        {middle.length>0 && <p className="footerbereich-text" dangerouslySetInnerHTML={{__html: middle}}></p>}
        {t.text.length>0 && <p className="footerbereich-text" dangerouslySetInnerHTML={{__html: textileConvert(t.text)}}></p>}
      </div>
    );
  }
}

export class Fuss extends React.Component {
  render () {
    const {appState, overlayOpen} = this.props;
    const paymentinfo = appState.paymentinfo;

    var bed1statictext = ''
    if (!paymentinfo.buchungtraditional) {
      bed1statictext = st['bwh.footer.bedingung1'];
      var kom = parseFloat(paymentinfo.kombuchung);
      if (0 > kom) {
        // Kommision ist fixpreis
        kom = (-1.0 * kom).toFixed(paymentinfo.decimal);
        bed1statictext = bed1statictext.replace('{Currency}', paymentinfo.waehrung);
        bed1statictext = bed1statictext.replace('{bookingfee}', kom);
      }
      else {
        // Prozent vom Preis
        bed1statictext = bed1statictext.replace('{Currency}', '');
        bed1statictext = bed1statictext.replace('{bookingfee}', kom + ' ' + st['bwh.footer.bedingung1.prozent']);
      }
    }
    
    var bed3statictext = '';
    if (0 < appState.resnr_booked && (appState.status==='confirm' || appState.status ==='change'))
    {
      bed3statictext = st['bwh.footer.bedingung31'];
      bed3statictext = bed3statictext.replace('{canceltime2}', appState.cancelTimeLang);
    }
    else {
      bed3statictext = st['bwh.footer.bedingung3'];
      bed3statictext = bed3statictext.replace('{canceltime}', appState.konfig.canceltime);
    }
    var url = '<a href="http://' + appState.host + '" target="_blank">' + appState.host + '</a>';
    if (appState.konfig.redirect && appState.konfig.agenturls[appState.sprachId] && appState.konfig.agenturls[appState.sprachId].length>0) {
      var tmp = document.createElement('a');
      tmp.href = appState.konfig.agenturls[appState.sprachId];
      var linkText = tmp.hostname;
      url = '<a href="' + appState.konfig.agenturls[appState.sprachId] + '" target="_blank">' + linkText + '</a>';
    }
    bed3statictext = bed3statictext.replace('{URL}', url);
    
    var footerinfotext = '';
    if (appState.priceagentCode.length > 0) {
      footerinfotext += 'agent:' + appState.priceagentCode + ' |';
    }
    if (appState.bookNoCC) {
      footerinfotext += ' bookflag:1 |';
    }
    if (0 < appState.resnr_changing) {
      footerinfotext += ' resnr changing:' + appState.resnr_changing + ' |';
    }
    if (0 < appState.resnr_booked) {
      footerinfotext += ' resnr booked:' + appState.resnr_booked + ' |';
    }
    footerinfotext = footerinfotext.substring(0, footerinfotext.length-2);
    
    
    return (
      <div id="footer_wrap" className="clearfix">
        <div id="footer" className="clearfix">
          <Block text={pt.hostel_bedingung1} middle={appState.hasRates?"":bed1statictext} first={true}/>
          <Block text={pt.hostel_bedingung2} middle="" first={false}/>
          <Block text={pt.hostel_bedingung3} middle={appState.hasRates?"":bed3statictext} first={false}/>
        </div>
        <div id="footer_infos" className="clearfix">
          <p className="footer_infos_text">{this.props.bwh_version}</p>
          <p className="footer_infos_trenn">|</p>
          <p className="footer_infos_text">{footerinfotext}</p>
          <p className="footer_infos_trenn">|</p>
          <div id="footer_infos_datenschutz_wrapper" className="handcursor" onClick={overlayOpen('hostel_privacy')} title={st['bwh.icontitle.link']}>
            <i id="footer_infos_datenschutz_icon" className="flaticon-arrows-1 footerlinkicon" title={st['bwh.icontitle.link']}></i>
            <p id="footer_infos_datenschutz">{st['bwh.footertext.datenschutz']}</p>
          </div>
          <p className="footer_infos_trenn">|</p>
          <div id="footer_infos_impressum_wrapper" className="handcursor" onClick={overlayOpen('hostel_contact')} title={st['bwh.icontitle.link']}>
            <i id="footer_infos_impressum_icon" className="flaticon-arrows-1 footerlinkicon"  title={st['bwh.icontitle.link']}></i>
            <p id="footer_infos_impressum">{st['bwh.footertext.impressum']}</p>
          </div>
          <p className="footer_infos_trenn">|</p>
          
        </div>
      </div>
    )
  }
}
export default Fuss;